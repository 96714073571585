import $ from 'jquery';
import React from 'react';
import { Modal, Checkbox, notification } from 'antd';
import SurveyView from './SurveyView';
import { Actions, Helpers, Survey, SurveyData } from '../types';
import getResponseFromImport from './helpers/getResponseFromImport';
import { uuid } from '../util';
import { getValueReferences } from './helpers/util';
import openAutomatchModal from './helpers/automatch';
import { playDragSound, playDropSound } from './helpers/sounds';
import db from '../db';

type SurveyAdminAppProps = {
  survey: Survey;
}

type SurveyAdminAppState = {
  ready: boolean;
  survey: Survey;
  surveyData: SurveyData;
  nextMasterValueID: string;
  categoriesEnabled: boolean;
}

class SurveyAdminApp extends React.Component<SurveyAdminAppProps, SurveyAdminAppState> {
  actions: Actions;
  helpers: Helpers;

  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      survey: props.survey,
      surveyData: {
        categories: [],
        admins: [],
        masterValues: [],
        aliasTable: {},
        responses: []
      },
      nextMasterValueID: uuid(),
      categoriesEnabled: false
    }

    let helpers: Helpers = this.helpers = {
      findMasterValueIndex: function (surveyData: SurveyData, id) {
        return surveyData.masterValues.findIndex(master => master.id === id);
      }
    }

    let actions: Actions = this.actions = {};

    actions.importResponse = async (responseFile) => {
      let { survey, surveyData } = this.state;
      let response = await getResponseFromImport(responseFile);

      // TODO: db.importResponse instead?
      let savedResponse = await db.createResponse(survey.id, response);

      surveyData = {...surveyData, responses: [...surveyData.responses, savedResponse]};

      this.setState({ surveyData });

      return savedResponse;
    };

    actions.fetchSurveyData = async () => {
      let { survey } = this.state;
      let surveyData = await db.fetchSurveyData(survey.id);
      let categoriesEnabled = surveyData.categories && surveyData.categories.length > 0;

      this.setState({ ready: true, surveyData, categoriesEnabled });
    }

    // pass (id, false) to unignore
    actions.ignoreResponse = async (responseID, ignored = true) => {
      let { survey, surveyData } = this.state;

      db.updateResponse(responseID, { ignored });

      // optimistic update
      let response = surveyData.responses.find(response => response.id === responseID);
      response.ignored = ignored;

      this.setState({ surveyData });
    }

    actions.updateResponseTags = async (responseID, tags) => {
      let { survey, surveyData } = this.state;

      db.updateResponse(responseID, { tags });

      let response = surveyData.responses.find(response => response.id === responseID);
      response.tags = tags;

      this.setState({ surveyData });

      return response;
    }

    actions.updateMasterValue = (id, attrs = {}) => {
      let { survey, surveyData } = this.state;
      let { masterValues } = surveyData;
      let index = helpers.findMasterValueIndex(surveyData, id);

      // optimistic update
      db.updateMasterValue(survey.id, id, attrs);
      masterValues[index] = {...masterValues[index], ...attrs};

      this.setState({ surveyData });
    }

    actions.deleteMasterValue = (id) => {
      let { survey, surveyData } = this.state;
      let { masterValues } = surveyData;
      let index = helpers.findMasterValueIndex(surveyData, id);

      db.deleteMasterValue(survey.id, id);

      surveyData.masterValues = [
        ...masterValues.slice(0, index),
        ...masterValues.slice(index + 1)
      ]

      // list is uncontrolled so we need to move the dom elements manually
      $(`.raw-value-list[data-id=${id}] .value`).appendTo('.unmapped-values .raw-value-list');

      this.setState({ surveyData });
    }

    actions.createMasterValue = (value) => {
      let { survey, surveyData, nextMasterValueID } = this.state;
      let id = nextMasterValueID;

      db.createMasterValue(survey.id, id, value);

      surveyData.masterValues.push({ id, value });

      this.setState({
        surveyData,
        nextMasterValueID: uuid()
      });
    }

    actions.move = (value, id) => {
      let { survey, surveyData, nextMasterValueID } = this.state;
      let { aliasTable } = surveyData;

      aliasTable[value] = id;

      if (id === nextMasterValueID) {
        actions.createMasterValue(value);
      }

      db.updateAlias(survey.id, value, id);

      this.setState({ surveyData });
    }

    // Unlike the move action this assumes the master value already exists.
    // This action is used by automatch.
    actions.moveAll = (values, id) => {
      let { survey, surveyData } = this.state;
      let { aliasTable } = surveyData;

      values.forEach(value => {
        aliasTable[value] = id;
        db.updateAlias(survey.id, value, id);
      })

      this.setState({ surveyData });
    }

    actions.automatch = (masterID) => {
      let app = this.getAppContext();
      openAutomatchModal(app, masterID);
    }

    actions.reviewValueReferences = (value) => {
      let { survey, surveyData } = this.state;
      let refs = getValueReferences(survey, surveyData, value);

      notification.open({
        message: value,
        description: <ul>{refs.map(r => <li>{r}</li>)}</ul>,
        duration: null
      });
    }

    actions.resetMasterValues = () => {
      let { survey, surveyData } = this.state;

      db.resetMasterValues(survey.id);

      surveyData.masterValues = [];
      surveyData.aliasTable = {};

      this.setState({ surveyData });
    }

    actions.addAdmin = (email) => {
      let { survey, surveyData } = this.state;

      db.addAdmin(survey.id, email);

      surveyData.admins = [...surveyData.admins, email];

      this.setState({ surveyData });
    }

    actions.dropAdmin = (email) => {
      let { survey, surveyData } = this.state;

      db.dropAdmin(survey.id, email);

      surveyData.admins = surveyData.admins.filter(admin => admin !== email);

      this.setState({ surveyData });
    }
  }

  render() {
    let app = this.getAppContext();
    return <SurveyView app={app} />;
  }

  getAppContext() {
    return {
      state: this.state,
      actions: this.actions,
      helpers: this.helpers
    }
  }
}

export default SurveyAdminApp;
