import React from 'react';
import { Dropdown, Menu, Tag } from 'antd';

const RawValue = ({ value, actions }) => {
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => actions.reviewValueReferences(value)}>Review references</Menu.Item>
    </Menu>
  );

  return (
    <div className='value drag-target' data-value={value}>
      <Dropdown overlay={menu} trigger={['contextMenu']}>
        <Tag color='blue'>
          <span className='ant-dropdown-link'>
            {value}
          </span>
        </Tag>
      </Dropdown>
    </div>
  )
}

export default RawValue;
