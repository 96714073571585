import './SurveyIndex.css';

import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Drawer, Button, List, Menu, Dropdown, Icon, Spin, notification } from 'antd';
import { Actions, Helpers, Session, Survey } from '../types';
import SurveyForm from './SurveyForm';

type SurveyIndexProps = {
  actions: Actions;
  helpers: Helpers;
  session: Session;
  surveys: Survey[];
}

class SurveyIndex extends React.Component<SurveyIndexProps> {
  render() {
    let { actions, surveys, session } = this.props;

    const renderItem = (survey) => {
      const edit = () => actions.editSurvey(survey.id);

      // TODO: use history?
      let actionButtons = [
        <Button.Group>
          <Button href={`#/${survey.id}`} target='_blank'>Run</Button>
          <Button href={`#/admin/edit/${survey.id}`}>Edit</Button>
          <Button href={`#/admin/surveys/${survey.id}`}>Manage</Button>
          {/*<Link to="#/admin/run" className="ant-btn">Foo</Link>*/}
        </Button.Group>
      ]

      return (
        <List.Item actions={actionButtons}>
          {survey.name}
        </List.Item>
      )
    }

    return (
      <div className="admin-app">
        <div className="survey-index">
          <header>
            <h1>System Effects</h1>

            {session.admin &&
              <p>
                <Button type="primary" href="#/admin/new">New survey</Button>
              </p>
            }

            {this.renderCurrentUserNav()}
          </header>

          <div className="survey-list">
            <List
              size="large"
              bordered
              dataSource={surveys}
              renderItem={renderItem}
            />
          </div>

          <Route path="/admin/new" children={this.renderNewSurveyForm} />
          <Route path="/admin/edit/:id" children={this.renderEditSurveyForm} />
        </div>
      </div>
    )
  }

  renderCurrentUserNav() {
    let { session, actions } = this.props;

    const menu = (
      <Menu>
        <Menu.Item>
          <Link to="/logout">Sign out</Link>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className='current-user-nav'>
        <Dropdown overlay={menu}>
          <a className="ant-dropdown-link" href="#/admin">
            {session.email} <Icon type="down" />
          </a>
        </Dropdown>
      </div>
    )
  }

  renderNewSurveyForm = ({ match, history }) => {
    let { actions } = this.props;
    const dismiss = () => history.replace('/admin');

    return <SurveyForm visible={!!match} isNew actions={actions} dismiss={dismiss} />;
  }

  renderEditSurveyForm = ({ match, history }) => {
    let { actions, helpers, surveys } = this.props;
    let survey;

    if (match) {
      survey = helpers.getSurvey(Number(match.params.id));
    }

    const dismiss = () => history.replace('/admin');

    return <SurveyForm visible={!!match} actions={actions} survey={survey} dismiss={dismiss} />;
  }
}

export default SurveyIndex;
