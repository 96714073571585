import React from 'react';
import classNames from 'classnames';
import { message, Button, Icon, Row, Col } from 'antd';
import { AppState, Response, Matrix, Graph } from '../types';
import AdjacencyMatrix from './AdjacencyMatrix';
import ResponseTranslationTable from './ResponseTranslationTable';
import ConnectionEditor from '../survey/RoughJSConnectionEditor';
import TagInput from './TagInput';
import {
  uuid,
  getUniqueValuesFromResponse,
  getOriginalMatrix,
  getGraphFromResponse,
  graphToAdjacencyMatrix,
  translatedMatrixToMasterMatrix,
  getMasterMatrixFromResponse
} from '../util';

import './ResponseView.css';

type ResponseViewProps = {
  app: AppState;
  response: Response;
}

class ResponseView extends React.Component<ResponseViewProps> {
  render() {
    let { app, response } = this.props;
    let { survey, surveyData } = app.state;

    let matrix: Matrix = getMasterMatrixFromResponse(survey, surveyData, response);
    let graph: Graph;

    if (response.source !== 'import') {
      // TODO: try generate graph from matrix for imported responses too
      graph = getGraphFromResponse(survey, response);
    }

    let className = classNames('response-view', {ignored: response.ignored});

    return (
      <div className={className}>
        <TagInput key={response.id} app={app} response={response} />

        <br /><br />
        
        <Row>
          <Col span={8}>
            <h4>Email</h4>
            <p>{response.email || 'none'}</p>

            <h4>Story</h4>
            <p>{response.story || 'none'}</p>

            <ResponseTranslationTable survey={survey} surveyData={surveyData} response={response} />
          </Col>

          <Col span={16}>
            <AdjacencyMatrix matrix={matrix} />
          </Col>
        </Row>

        {graph &&
          <div className='response-view-graph'>
            {/* key used to force editor to update */}
            {<ConnectionEditor key={uuid()} graph={graph} resize={false} readonly />}
          </div>
        }
      </div>
    )
  }
}

export default ResponseView;
