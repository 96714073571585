import $ from "jquery";
import _ from "lodash";
import { AppState, TranslationOptions } from "../../types";
import { getAggregateMatrixFromResponses } from "../../util";
import { calculateMetrics } from "../helpers/metrics";

export default function createScatterPlot(
  app: AppState,
  responses,
  containerSelector
) {
  const state = {
    categorize: false,
    ranked: false,
    x: "outdegree",
    y: "indegree",
    r: "pagerank",
    c: "pagerank",
  };

  render();

  function render() {
    let { survey, surveyData } = app.state;
    let translationOptions: TranslationOptions = {
      categorize: state.categorize,
    };
    let matrix = getAggregateMatrixFromResponses(
      survey,
      surveyData,
      responses,
      translationOptions
    );
    let data = calculateMetrics(matrix);

    const rawX = d => d[state.x];
    const rawY = d => d[state.y];
    const rawRadius = d => d[state.r];
    const rawColor = d => d[state.c];

    const d3 = window["d3"];
    const $el = $(containerSelector);

    // reset the dataviz
    $el.empty();

    $el.on("mousemove", event => {
      let $target = $(event.target);

      if ($target.is(".dot-circle")) {
        let index = $target.index() + 1; // nth-child is 1-based
        $(".dot-circle, .dot-label-group").attr("data-layer", "background");
        $(
          `.dot-circle:nth-child(${index}), .dot-label-group:nth-child(${index})`
        ).attr("data-layer", "foreground");
      } else {
        $(".dot-circle, .dot-label-group").attr("data-layer", "foreground");
      }
    });

    $el.append(`
      <div>
        <div class='categorize-toggle-group'>
          <label><input type="radio" name="categorizeToggle" value="0" ${
            state.categorize ? "" : "checked"
          } /> Uncategorized</label>
          <label><input type="radio" name="categorizeToggle" value="1" ${
            state.categorize ? "checked" : ""
          } /> Categorized</label>
        </div>
        <div>
          <label><input type="radio" name="modeToggle" value="raw" ${
            state.ranked ? "" : "checked"
          } /> Raw</label>
          <label><input type="radio" name="modeToggle" value="ranked" ${
            state.ranked ? "checked" : ""
          } /> Ranked</label>
        </div>
      </div>
    `);

    if (!app.state.categoriesEnabled) {
      $el.find(".categorize-toggle-group").hide();
    }

    $el.find("[name=categorizeToggle]").change(event => {
      state.categorize = (event.target as HTMLInputElement).value === "1";
      render();
    });

    $el.find("[name=modeToggle]").change(event => {
      state.ranked = (event.target as HTMLInputElement).value === "ranked";
      refresh();
    });

    // set the dimensions and margins of the graph
    let margin = { top: 50, right: 300, bottom: 80, left: 60 };
    let width = $el.width() - margin.left - margin.right;
    let height = $el.height() - margin.top - margin.bottom;

    // append the svg object to the body of the page
    let svg = d3
      .select(containerSelector)
      .append("svg")
      .attr("class", "scatter-plot")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Add X axis
    let x = d3
      .scaleLinear()
      .domain([0, d3.max(data, rawX)])
      .range([0, width]);
    svg
      .append("g")
      .attr("class", "x-axis-group")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x));
    svg
      .append("text")
      .attr("class", "x-axis-label")
      .attr("transform", `translate(${width / 2}, ${height + 35})`)
      .style("text-anchor", "middle")
      .text(state.x);

    // Add Y axis
    let y = d3
      .scaleLinear()
      .domain([0, d3.max(data, rawY)])
      .range([height, 0]);
    svg
      .append("g")
      .attr("class", "y-axis-group")
      .call(d3.axisLeft(y));
    svg
      .append("text")
      .attr("class", "y-axis-label")
      .attr("transform", "rotate(-90)")
      .attr("y", -35)
      .attr("x", 0 - height / 2)
      // .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text(state.y);

    // Add color legend
    let legendSvg = svg
      .append("g")
      // .attr('width', 20)
      // .attr('height', height)
      .attr("transform", `translate(${width + margin.right / 2}, 0)`);

    let legendAxisScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, rawColor)])
      .range([height, 0]);
    legendSvg
      .append("g")
      .attr("class", "color-axis-group")
      .call(d3.axisLeft(legendAxisScale));
    legendSvg
      .append("text")
      .attr("class", "color-axis-label")
      .attr("transform", "rotate(90)")
      .attr("y", -30)
      .attr("x", height / 2)
      .style("text-anchor", "middle")
      .text(state.c);

    let legendGradient = legendSvg
      .append("defs")
      .append("linearGradient")
      .attr("id", "gradient")
      .attr("x1", "0%") // bottom
      .attr("y1", "100%")
      .attr("x2", "0%") // to top
      .attr("y2", "0%")
      .attr("spreadMethod", "pad");

    let legendColorScale = d3
      .scaleSequential(d3.interpolateViridis)
      .domain([0, 1]);
    for (let i = 0; i < 1; i += 0.1) {
      legendGradient
        .append("stop")
        .attr("offset", i)
        .attr("stop-color", legendColorScale(i))
        .attr("stop-opacity", 1);
    }

    legendSvg
      .append("rect")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("width", 20)
      .attr("height", height)
      .style("fill", "url(#gradient)");

    let r = d3
      .scaleLinear()
      .domain([0, d3.max(data, rawRadius)])
      .range([10, 30]);

    let colorScale = d3
      .scaleSequential(d3.interpolateViridis)
      .domain([0, d3.max(data, rawColor)]);

    // Add dots
    svg
      .append("g")
      .selectAll(".dot-circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("class", "dot-circle")
      .attr("cx", d => x(rawX(d)))
      .attr("cy", d => y(rawY(d)))
      .attr("r", d => r(rawRadius(d)))
      .style("fill", d => colorScale(rawColor(d)))
      .style("opacity", d => 1)
      .style("stroke", "white")
      .style("stroke-width", 1);
    // .on("mouseover", () => console.log("mouseover"))
    // .on("mousemove", mousemove)
    // .on("mouseleave", mouseleave)

    // Add labels
    let labelsGroup = svg.append("g");
    let labelGroup = labelsGroup
      .selectAll(".dot-label-group")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "dot-label-group")
      .attr("transform", d => `translate(${x(rawX(d))}, ${y(rawY(d))})`);

    labelGroup
      .append("text")
      .text(d => d.label)
      .style("fill", "#fff")
      .style("stroke", "#fff")
      .style("stroke-opacity", 0.5)
      .style("stroke-width", 2)
      .attr("x", 0)
      .attr("y", 4);

    labelGroup
      .append("text")
      .text(d => d.label)
      .style("fill", "#000")
      .style("opacity", d => (d.orphan ? 0.1 : 1))
      .attr("x", 0)
      .attr("y", 4);

    refresh();

    function refresh() {
      if (state.ranked) {
        state.x = "outdegree rank";
        state.y = "indegree rank";
        state.r = "pagerank rank";
        state.c = "pagerank rank";
      } else {
        state.x = "outdegree";
        state.y = "indegree";
        state.r = "pagerank";
        state.c = "pagerank";
      }

      // refresh scale domains
      x.domain([0, d3.max(data, rawX)]);
      y.domain([0, d3.max(data, rawY)]);
      r.domain([0, d3.max(data, rawRadius)]);
      colorScale.domain([0, d3.max(data, rawColor)]);
      legendAxisScale.domain([0, d3.max(data, rawColor)]);

      // refresh axis ticks
      d3.select(".x-axis-group").call(d3.axisBottom(x));
      d3.select(".y-axis-group").call(d3.axisLeft(y));
      d3.select(".color-axis-group").call(d3.axisLeft(legendAxisScale));

      // refresh axis labels
      d3.select(".x-axis-label").text(state.x);
      d3.select(".y-axis-label").text(state.y);
      d3.select(".color-axis-label").text(state.c);

      // refresh color, position, etc
      d3.selectAll(".dot-circle")
        .transition()
        .attr("cx", d => x(rawX(d)))
        .attr("cy", d => y(rawY(d)))
        .attr("r", d => r(rawRadius(d)))
        .style("fill", d => colorScale(rawColor(d)));

      d3.selectAll(".dot-label-group")
        .transition()
        .attr("transform", d => `translate(${x(rawX(d))}, ${y(rawY(d))})`);
    }
  }
}
