import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

class SelectInput extends React.Component {
  render() {
    let { values } = this.props;

    let options = values.map(({ label, value }) => {
      return <Option key={value} value={value}>{label}</Option>;
    })

    return (
      <Select onChange={this.handleChange}>
        {options}
      </Select>
    )
  }

  handleChange = (value) => {
    this.props.onChange(value);
  }
}

export default SelectInput;
