import React, { Component } from 'react';
import { HashRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { Drawer, Button, List, Icon, Spin, notification } from 'antd';
import LandingPage from './landing/LandingPage';
import Privacy from './legal/Privacy';
import Terms from './legal/Terms';
import GDPR from './legal/GDPR';
import AdminApp from './admin/AdminApp';
import SurveyApp from './survey/SurveyApp';
import './App.css';

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path='/' exact component={LandingPage}></Route>
          <Route path='/privacy' exact component={Privacy}></Route>
          <Route path='/terms' exact component={Terms}></Route>
          <Route path='/gdpr' exact component={GDPR}></Route>
          <Route path='/(admin|login|logout)' component={AdminApp}></Route>
          <Route path='/:id' children={this.renderSurvey}></Route>
        </Switch>
      </Router>
    )
  }

  renderSurvey = ({ match }) => {
    return <SurveyApp surveyID={match.params.id} responseID={null} />;
  }
}

export default App;
