import $ from 'jquery';
import React from 'react';

const AUTOCOMPLETE_MIN_LENGTH = 1;

class TextInput extends React.Component {
  constructor() {
    super();

    this.state = {
      hasFocus: false,
      autocompleteOpen: false,
      autocompleteSuggestions: [],
      autocompleteIndex: -1
    }
  }

  render() {
    let { value, placeholder, autocomplete, onDelete } = this.props;
    let { hasFocus, autocompleteSuggestions } = this.state;
    let showAutocomplete = hasFocus && autocompleteSuggestions.length > 0;

    return (
      <div className='text-input'>
        <input
          ref='input'
          type='text'
          value={value}
          placeholder={placeholder}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
        />
      {showAutocomplete && this.renderAutocomplete()}
      </div>
    )
  }

  renderAutocomplete() {
    let { hasFocus, autocompleteSuggestions, autocompleteIndex } = this.state;
    let children = autocompleteSuggestions.map((value, index) => {
      let className = index === autocompleteIndex ? 'active' : null;

      // We need to use mousedown for this one or the blur event will trigger
      // before the click event and the menu will already have been removed
      // from the DOM.
      const select = () => {
        this.updateValue(value);
        // this.focusNextInput();
      }

      return <li key={value} className={className} onMouseDown={select}>{value}</li>;
    })

    return <ul className='autocomplete-list'>{children}</ul>;
  }

  shiftAutocompleteIndex(delta) {
    let { enteredValue, autocompleteSuggestions, autocompleteIndex } = this.state;
    let selectedValue;

    autocompleteIndex += delta;

    if (autocompleteIndex >= autocompleteSuggestions.length) {
      autocompleteIndex = -1;
    } else if (autocompleteIndex < -1) {
      autocompleteIndex = autocompleteSuggestions.length - 1;
    }

    if (autocompleteIndex === -1) {
      selectedValue = enteredValue;
    } else {
      selectedValue = autocompleteSuggestions[autocompleteIndex];
    }

    this.setState({ autocompleteIndex });
    this.props.onChange(selectedValue);
  }

  handleFocus = () => {
    this.setState({hasFocus: true});
  }

  handleBlur = (event) => {
    // have to use setTimeout to give the onClick handler a chance to fire
    // before being removed from the DOM
    let { target, currentTarget } = event;

    this.setState({hasFocus: false});
    this.props.onBlur && this.props.onBlur();
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.focusNextInput();
    } else if (event.key === 'Escape') {
      // TODO: blur instead?
      this.setState({hasFocus: false});
    } else if (event.key === 'ArrowDown') {;
      event.preventDefault(); // prevent cursor jump to end
      this.shiftAutocompleteIndex(+1);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault(); // prevent cursor jump to start
      this.shiftAutocompleteIndex(-1);
    }
  }

  handleChange = (event) => {
    let value = event.target.value;
    this.updateValue(value);
  }

  updateValue(value) {
    let { autocomplete } = this.props;
    let autocompleteSuggestions = [];

    if (autocomplete && value && value.length >= AUTOCOMPLETE_MIN_LENGTH) {
      autocompleteSuggestions = autocomplete.filter(string => {
        let stringLower = string.toLowerCase();
        let valueLower = value.toLowerCase();

        return stringLower !== valueLower && stringLower.indexOf(valueLower) !== -1;
      })
    }

    // needs to happen now or cursor jumps if you try to edit middle
    this.props.onChange(value);

    this.setState({
      enteredValue: value,
      autocompleteSuggestions
    })
  }

  // TODO: make this an action?
  focusNextInput() {
    let $inputs = $('input[type=text]');
    let index = $inputs.index(this.refs.input);

    if (index < $inputs.length - 1) {
      let $nextInput = $inputs.eq(index + 1);
      setTimeout(function() { $nextInput.focus(); }, 0);
    }
  }
}

export default TextInput;
