import './LandingPage.css';

import React from 'react';

class LandingPage extends React.Component {
  render() {
    return (
      <div className='homepage'>
        <div className='container'>
          <img className="logo" src="/images/logo.png" alt="System Effects logo" />

          <div className='block'>
            <blockquote>
              Shifting the conversation from symptoms to systems
            </blockquote>

            <p>
              When trying to change the world around us, we tend to assume everyone experiences the world in the same way.
            </p>

            <p>
              That assumption is spectacularly—and scientifically—wrong.
            </p>

            <p>
              Individuals' lives are complex, unique and varied. The tools and methodologies we
              use to understand and address the issues impacting individuals must recognize that complexity.
            </p>

            <p>
              System Effects helps decision makers respond to complex problems while
              at the same time embracing the uniqueness of individual experience.
            </p>

            <div className='hidden'>
              <p>
                Public policy interventions have historically assumed that citizens are the same.
                Even within segments of populations, the assumption is that individuals within
                different segments–solo parents, new migrants, unemployed, etc–face the same challenges.
                This is not the case; disadvantage doesn't unfold in this way.
                Individuals' lives are complex, unique and varied, and the categorisation of this experience does not lead to public policy that meets their needs.
              </p>

              <p>
                Whether we're focusing on what gives people purpose in the workplace,
                or why individuals struggle to access healthy and nutritious food,
                it is important to recognise that individuals are different—and the
                systems that structure their lives vary based on context and circumstance.
              </p>

              <p>
                System Effects helps to understand individual circumstance as complex, unique and varied, and gives decision makers tools to effectively respond to the challenges they face.
              </p>
            </div>
          </div>

          <h1>Introducing System Effects</h1>
          <p>
            System Effects is a research methodology
            developed by <a href="https://www.lukecraven.com/" target="_blank">Dr Luke Craven</a>
            {' at '}<a href="https://www.unsw.adfa.edu.au/" target="_blank">UNSW Canberra</a>,
            that captures the varied nature of individual experience to enable better intervention design.
            The System Effects platform was developed by <a href="https://kumu.io" target="_blank">Kumu</a> in collaboration with Luke.
          </p>

          <p>
            Drawing on soft systems, fuzzy cognitive mapping, and graph theory,
            System Effects can be used to ask a range of questions about a given issue, focusing
            on how different impacts, barriers, and enablers exist and are perceived within the system that surrounds it.
          </p>

          <p>
            By beginning from the user-understanding of complex systems, the methodology helps
            re-centre lived experience in social science and policymaking practice.
          </p>

          <blockquote>
            System Effects supports the design of effective interventions
            by giving decision-makers tools to understand patterns that emerge across groups and communities,
            while at the same time emphasising the varied nature of individual experience.
          </blockquote>

          <p className='hidden'>
            Individual experiences of complex systems are varied, and depend on individual circumstance and context.
            System Effects captures the diversity of factors that impact people's experiences and the varied connections between them.
            System Effects can capture and provide decision makers tools to aggregate and quantify these individual system models at the population level.
          </p>

          <div className='block process'>
            <h1>How does it work?</h1>

            <p>
              Starting from a common focus, participants are asked to explore the
              barriers, impacts, and enablers present in their own lives.
              The result of this process is a personal systems map that captures the
              individual's unique understanding and experience of the issue.
            </p>

            <p>
              We can then layer these individual maps to build a picture of the wider community experience.
              Aggregating maps this way ensures no individual variable or causal connection is ignored,
              while highlighting the shared experiences emerging at the population-level.
            </p>

            <div className="hidden">
              <p>
                Aggregating individual models in this way accounts for every variable identified across
                the sample and the diverse range of causal connections between them, as well
                as making clear the intensity and frequency of particular connections
                at the population-level.
              </p>
            </div>

            <img src="/images/layers.png" />

            <img src="/images/network.png" />

            <blockquote>
              By beginning from the user-understanding of complex systems, the methodology helps to re-centre lived experience in social science and policymaking practice.
            </blockquote>
          </div>

          <div className='block'>
            <h1>How can it help me?</h1>
            <p>
              The results of the System Effects process can be used to:
            </p>

            <ul>
              <li>
                <h3>Inform policy and program design</h3>
                <p>
                  How can policies most effectively address complex systems,
                  given the diversity of individual experience?
                </p>
              </li>

              <li>
                <h3>Guide intervention implementation</h3>
                <p>
                  How can the implementation of policies and programs be
                  effectively tailored to the systemic dynamics of particular contexts?
                </p>
              </li>

              <li>
                <h3>Evaluate systemic impacts</h3>
                <p>
                  How can we assess the systemic impact of particular interventions
                  and their interactions with the contexts in which they are deployed?
                </p>
              </li>
            </ul>
          </div>

          <div className='block'>
            <h1>How has it been used before?</h1>

            <p>
              System Effects is being applied to a wide range of issues by national, state, and local decision makers across the world.
            </p>

            <ul>
              <li>Understanding the barriers to job market entry in Oslo, in partnership with the Norwegian Labour and Welfare Administration (NAV)</li>
              <li>Understanding the systemic impact of disaster events in Sydney, in partnership with Resilient Sydney and the NSW Office of Emergency Management</li>
              <li>Supporting social workers to deliver systemic care to persons facing homelessness in Newcastle, UK, in partnership with Newcastle City Council</li>
              <li>Supporting the development of policy to prevent food borne disease in Cambodia, in partnership with the International Livestock Research Institute (ILRI) and USAID</li>
              <li>Supporting effective environmental stewardship in New York, in partnership with the US Forest Service</li>
            </ul>
          </div>

          <div className='block'>
            <h1>Run your own System Effects survey</h1>
            <p>
              System Effects surveys are available through our online platform for $700/survey*.
              Each survey explores a single dimension (impacts, enablers, or barriers) of a particular focus
              and includes an unlimited number of responses.
            </p>

            <p>
              If you would like help facilitating the survey process,
              we offer consulting services at $200/hour or $2000/day.
              Please email <a href="mailto:info@systemeffects.com">info@systemeffects.com</a> to discuss options
              and explore which route is best for your project.
            </p>

            <p className='text-center'>
              <em>* All prices in USD</em>
            </p>
          </div>

          <blockquote>
            Shifting the conversation from symptoms to systems
          </blockquote>

          <footer>
            <div className="links">
              <a href="/#/terms">Terms</a> |
              <a href="/#/privacy">Privacy</a> |
              <a href="/#/gdpr">GDPR</a>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}

export default LandingPage;
