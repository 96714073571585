import $ from 'jquery';
import React from 'react';
import { Modal, Checkbox } from 'antd';
import { getMaster, getMatchingValues } from './util';
import { playDropSound } from './sounds';

export default function automatch(app, masterID) {
  let { actions, state } = app;
  let { surveyData } = state;
  let master = getMaster(surveyData, masterID);
  let matching = getMatchingValues(surveyData, masterID);

  if (matching.length === 0) {
    Modal.info({content: 'No matches found'});
    return;
  }

  let checked = {};

  let checkboxes = matching.map(match => {
    const [ score, value, alias ] = match;
    const handleChange = value => event => checked[value] = event.target.checked;

    return <div key={value}><Checkbox onChange={handleChange(value)}>{value}</Checkbox></div>;
  })

  const AutomatchModal = () => {
    return (
      <div>
        <p>
          Please select the values you would like to map to <strong>{master.value}</strong> below:
        </p>

        {checkboxes}
      </div>
    )
  }

  function getChecked() {
    let values = [];

    Object.keys(checked).forEach(value => {
      if (checked[value]) values.push(value);
    })

    return values;
  }

  Modal.confirm({
    title: <span>{matching.length} potential matches found</span>,
    content: <AutomatchModal />,
    width: '50vw',
    icon: 'thunderbolt',
    okText: 'Save',
    onOk() {
      playDropSound();

      let values = getChecked();
      values.forEach(value => $(`.unmapped-values [data-value="${value}"]`).appendTo(`.drop-target[data-id="${masterID}"]`));

      actions.moveAll(values, masterID);
    },
    onCancel() {},
  });
}
