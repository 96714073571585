import React from 'react';
import {
  Drawer, Form, Button, Col, Row, Input, Select, DatePicker, Icon,
} from 'antd';

const { Option } = Select;

class SurveyForm extends React.Component {
  handleDismiss = () => {
    let { form, actions } = this.props;
    form.resetFields();
    this.props.dismiss();
  };

  handleError = (error) => {
    console.log("TODO: handle error", error);
  }

  handleSubmit = (event) => {
    let { form, actions, survey, isNew } = this.props;

    event.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        if (isNew) {
          actions.createSurvey(values).then(this.handleDismiss, this.handleError);
        } else {
          actions.updateSurvey(survey.id, values).then(this.handleDismiss, this.handleError);
        }
      }
    });
  }

  handleDelete = (event) => {
    let { actions, survey } = this.props;
    event.preventDefault();
    actions.deleteSurvey(survey.id).then(this.handleDismiss, this.handleError);
  }

  render() {
    const { visible, survey, isNew } = this.props;
    const { getFieldDecorator } = this.props.form;

    // TODO: handle defaults here instead of burying them within the form
    let values = survey || {};

    return (
      <div>
        <Drawer
          title={isNew ? "Create a new survey" : "Edit survey"}
          width={720}
          onClose={this.handleDismiss}
          visible={visible}
          style={{
            overflow: 'auto',
            height: 'calc(100% - 108px)',
            paddingBottom: '108px',
          }}
        >
          <Form layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Name">
                  {getFieldDecorator('name', {
                    initialValue: values.name,
                    rules: [{ required: true, message: 'Please enter survey name' }],
                  })(<Input placeholder="Please enter survey name" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Focus">
                  {getFieldDecorator('focus', {
                    initialValue: values.focus,
                    rules: [{ required: true, message: 'Please enter survey focus' }],
                  })(<Input placeholder="Please enter survey focus" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Focus direction">
                  {getFieldDecorator('direction', {
                    initialValue: values.direction,
                    rules: [{ required: true, message: 'Please select a direction' }],
                  })(
                    <Select placeholder="Please select a direction">
                      <Option value="upstream">Explore upstream causes/barriers</Option>
                      <Option value="downstream">Explore downstream impact/effects</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Introduction">
                  {getFieldDecorator('intro', {
                    initialValue: values.intro,
                  })(<Input.TextArea placeholder="Why are we here? What is this about?" autosize={{ minRows: 3, maxRows: 6 }} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Intake questionnaire">
                  {getFieldDecorator('intake', {
                    initialValue: values.intake,
                  })(<Input.TextArea placeholder="Paste JSON questionnaire template here" autosize={{ minRows: 3, maxRows: 6 }} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="1st prompt">
                  {getFieldDecorator('prompt1', {
                    initialValue: values.prompt1,
                    rules: [{ required: true, message: 'Please enter prompt' }],
                  })(<Input placeholder="Please enter 1st prompt" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="2nd prompt">
                  {getFieldDecorator('prompt2', {
                    initialValue: values.prompt2,
                    rules: [{ required: true, message: 'Please enter prompt' }],
                  })(<Input placeholder="Please enter 2nd prompt" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Story prompt">
                  {getFieldDecorator('promptStory', {
                    initialValue: values.promptStory,
                  })(<Input placeholder="Would you mind sharing a personal story about how _______ has affected you?" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Thank you note">
                  {getFieldDecorator('thanks', {
                    initialValue: values.thanks,
                  })(<Input placeholder="Thank you for helping us ________!" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Show newsletter form?">
                  {getFieldDecorator('newsletter', {
                    initialValue: values.showNewsletterForm,
                  })(<Input type="checkbox" />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
            }}
          >
            <Row>
              <Col span={12}>
                {!isNew &&
                  <Button onClick={this.handleDelete} type="danger">
                    Delete
                  </Button>
                }
              </Col>
              <Col span={12}>
                <div style={{textAlign: "right"}}>
                  <Button onClick={this.handleDismiss} style={{ marginRight: 8 }}>
                    Cancel
                  </Button>
                  <Button onClick={this.handleSubmit} type="primary">
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default Form.create()(SurveyForm);
