import React from 'react';
import { Row, Col, Input, Tag, Button, Icon } from 'antd';
import { AppState, Actions, MasterValue } from '../types';
import RawValueList from './RawValueList';

import './MasterValueListItem.css';

type Props = {
  app: AppState;
  code: any;
  master: MasterValue;
  aliases?: string[];
  readonly?: boolean;
  isNew?: boolean;
}

class MasterValueListItem extends React.Component<Props> {
  // constructor() {
  //   super();
  //
  //   this.state = {
  //     value: ''
  //   }
  // }

  render() {
    let { app, readonly, code, master, aliases } = this.props;
    let { categories } = app.state.surveyData;
    let input;

    if (readonly) {
      input = <Input addonBefore={code} value={master.value} disabled />;
    } else {
      input = <Input
        addonBefore={code}
        suffix={<Icon type='thunderbolt' onClick={this.handleAutomatch} />}
        defaultValue={master.value}
        placeholder='Enter master value'
        onBlur={this.handleValueChange}
        onPressEnter={this.handleValueChange}
      />
    }

    return (
      <li className='master-value-list-item'>
        <Row>
          <Col span={6}>
            {input}
          </Col>

          <Col span={18}>
            {this.renderCategorySelect()}

            <div className='value-list'>
              <RawValueList master={master} values={aliases} actions={app.actions} />
            </div>
          </Col>
        </Row>
      </li>
    )
  }

  renderCategorySelect() {
    let { app, readonly, code, master, aliases } = this.props;
    let { categories } = app.state.surveyData;

    if (categories && categories.length > 0) {
      return (
        <select
          className='ant-input master-value-category'
          defaultValue={master.category}
          onChange={this.handleCategoryChange}
        >
          <option value=''>Uncategorized</option>
          {categories.map(category => <option value={category}>{category}</option>)}
        </select>
      )
    } else {
      return null;
    }
  }

  handleValueChange = (event) => {
    let { app, master, isNew } = this.props;
    let { value } = event.target;

    value = value.trim();

    if (value) {
      if (isNew) {
        app.actions.createMasterValue(value);
      } else if (value !== master.value) {
        app.actions.updateMasterValue(master.id, { value });
      }
    } else {
      app.actions.deleteMasterValue(master.id);
    }
  }

  handleCategoryChange = (event) => {
    let { app, master } = this.props;
    let { value } = event.target;

    if (value !== master.category) {
      app.actions.updateMasterValue(master.id, { category: value });
    }
  }

  handleAutomatch = () => {
    let { app, master } = this.props;
    app.actions.automatch(master.id);
  }
}

export default MasterValueListItem;
