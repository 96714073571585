import React from 'react';
import { Checkbox } from 'antd';

class CheckboxInput extends React.Component {
  constructor() {
    super();
    this.state = {checked: []};
  }

  render() {
    let { values } = this.props;

    let inputs = values.map(({ label, value }, index) => {
      let onChange = (event) => this.handleChange(event, index);
      return <Checkbox key={value} value={value} onChange={onChange}>{label}</Checkbox>;
    })

    return <div>{inputs}</div>;
  }

  handleChange = (event, index) => {
    let { checked } = this.state;
    checked[index] = event.target.checked;
    this.setState({ checked }, this.notifyChange);
  }

  notifyChange = () => {
    let { values } = this.props;
    let { checked } = this.state;

    let checkedValues = checked
      .map((isChecked, index) => isChecked ? values[index].value : null)
      .filter(value => value);

    this.props.onChange(checkedValues);
  }
}

export default CheckboxInput;
