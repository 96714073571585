import XLSX from 'xlsx';
import papaparse from 'papaparse';
import { AppState, Response, Matrix } from '../../types';

async function getResponseFromImport(file: File) {
  let matrix = await (/xlsx$/.test(file.name) ? parseXLSX(file) : parseCSV(file));
  let response: Response = { source: 'import', tags: file.name, data: { matrix } };
  return response;
}


async function parseXLSX(file: File): Promise<Matrix> {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      let data = new Uint8Array(<ArrayBuffer>reader.result);
      let workbook = XLSX.read(data, {type: 'array'});

      // currently only process the first worksheet
      let worksheetName = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[worksheetName];
      let csv = XLSX.utils.sheet_to_csv(worksheet);
      let matrix = csvToMatrix(csv);

      resolve(matrix);
    };

    reader.onerror = (error) => reject(error);

    reader.readAsArrayBuffer(file);
  })
}


// file.type "text/csv"
async function parseCSV(file: File): Promise<Matrix> {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => resolve(csvToMatrix(reader.result));
    reader.onerror = (error) => reject(error);
    reader.readAsText(file);
  })
}

function csvToMatrix(csv) {
  let { data } = papaparse.parse(csv);

  let matrix: Matrix = {
    labels: data[0].slice(1),
    data: data.slice(1).map(row => row.slice(1).map(Number))
  }

  // remove empty rows at the end
  matrix.data = matrix.data.filter(row => row.length > 0);

  return matrix;
}

export default getResponseFromImport;
