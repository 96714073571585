import sortBy from "lodash/sortBy";
import { compareTwoStrings } from "string-similarity";
import {
  getUniqueValuesFromResponse,
  getEdgeListFromResponse,
} from "../../util";
import { Survey, SurveyData, Response } from "../../types";

export function getMaster(surveyData, masterID) {
  let { masterValues } = surveyData;
  return masterValues.find(master => master.id === masterID);
}

// TODO: how do we want to handle ignore?
export function getMasterID(aliasTable, value) {
  return aliasTable[value];
}

export function getMasterIDSet(surveyData) {
  let { masterValues } = surveyData;
  let set = new Set();
  set.add("ignore");
  masterValues.forEach(master => set.add(master.id));
  return set;
}

export function getUnmappedValues(surveyData): any[] {
  let { masterValues, aliasTable, responses } = surveyData;
  let validMasterIDs = getMasterIDSet(surveyData);
  let unmappedValues = new Set();

  responses.forEach(response => {
    if (response.ignored) return;

    let values = getUniqueValuesFromResponse(response);
    values.forEach(value => {
      let id = getMasterID(aliasTable, value);

      // not mapped or master value no longer exists
      if (!id || !validMasterIDs.has(id)) {
        unmappedValues.add(value);
      }
    });
  });

  return Array.from(unmappedValues);
}

export function getAliases(surveyData, masterID) {
  let { aliasTable } = surveyData;
  let values = [];

  Object.keys(aliasTable).forEach(value => {
    let id = aliasTable[value];

    if (id === masterID) {
      values.push(value);
    }
  });

  return values;
}

export function getMatchingValues(surveyData, masterID) {
  let master = getMaster(surveyData, masterID);

  if (!master) return [];

  let aliases = getAliases(surveyData, masterID);
  let unmapped = getUnmappedValues(surveyData);
  let cutoff = 0.2;
  let bestScores: any = {};

  aliases.unshift(master.value);
  aliases.forEach(alias => {
    unmapped.forEach(value => {
      let score = compareTwoStrings(alias, value);

      if (score >= cutoff) {
        let best = bestScores[value];

        if (!best || score > best.score) {
          bestScores[value] = { score, alias };
        }
      }
    });
  });

  let matches = [];

  Object.keys(bestScores).forEach(value => {
    let { score, alias } = bestScores[value];
    matches.push([score, value, alias]);
  });

  // sorted by descending score
  return sortBy(matches, set => -set[0]);
}

export function getValueReferences(
  survey: Survey,
  surveyData: SurveyData,
  value: string
) {
  let { responses } = surveyData;
  let refs = [];

  let lowerValue = value.toLowerCase();

  responses.forEach(response => {
    let edges = getEdgeListFromResponse(survey, response);

    edges.forEach(({ from, to }) => {
      let lowerFrom = from.toLowerCase();
      let lowerTo = to.toLowerCase();

      if (lowerFrom === lowerValue || lowerTo === lowerValue) {
        refs.push(`${from} --> ${to}`);
      }
    });
  });

  return refs;
}
