import debounce from 'lodash/debounce';
import React from 'react';

type Props = {
  onChange: Function;
}

class FilterInput extends React.Component<Props> {
  input: any;

  constructor(props) {
    super(props);

    this.input = React.createRef();
    this.handleFilterChange = debounce(this.handleFilterChange, 500);
  }

  render() {
    return (
      <input ref={this.input} className='filter-input ant-input' placeholder='Filter responses by tags' onChange={this.handleFilterChange} />
    )
  }

  handleFilterChange = () => {
    let filter;
    let rawFilter = this.input.current.value;

    if (rawFilter) {
      try {
        filter = new RegExp(rawFilter);
      } catch (error) {} // ignore invalid expression errors, likely not done typing
    } else {
      filter = new RegExp(".*");
    }

    // onChange callback only fires if filter can be parsed
    if (filter) {
      this.props.onChange(filter);
    }
  }
}

export default FilterInput;
