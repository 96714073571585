const Sounds = {
  // https://shopify.github.io/draggable/
  // https://shopify.github.io/draggable/assets/js/app.js
  up: new Audio('https://shopify.github.io/draggable/assets/audio/animation-up-2.mp3'),
  down: new Audio('https://shopify.github.io/draggable/assets/audio/animation-down-2.mp3'),
}

const play = (sound) => {
  let audio = Sounds[sound];
  audio.currentTime = 0;
  audio.play();
}

export const playDragSound = () => play('up');
export const playDropSound = () => play('down');
