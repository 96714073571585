// import './vendor/rough';
import $ from 'jquery';
import React from 'react';
import { splitString } from '../util';
import './RoughJSConnectionEditor.css';

// WTF?
// import rough from "roughjs";
const rough = window.rough;

const NODE_COLOR = '#7FC294';
const NODE_RADIUS = 10;

class ConnectionEditor extends React.Component {
  constructor() {
    super();

    this.history = [];

    this.actions = {
      undo: () => {
        if (this.history.length === 0) return;

        let edge = this.history.pop();
        this.edgeLayer.removeChild(this.edgeLayer.lastChild);

        this.props.onEdgeCreatedUndo(edge);
      },

      // noop at this point
      redo: () => {}
    };
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleResize);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  componentDidMount() {
    this.resizeSVG();
    this.renderSVG();
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return <svg id='svg-connection-editor' ref='svg'></svg>;
  }

  resizeSVG() {
    // don't want these resizing in the admin app
    if (this.props.resize === false) return;

    let { svg } = this.refs;
    let width = '100%';
    let height = window.innerHeight - $('.screen-heading').outerHeight(true) - $('.screen-footer').outerHeight(true);

    $(svg)
      .width(width)
      .height(height)
  }

  renderSVG() {
    let { svg } = this.refs;
    let { readonly } = this.props;
    let { handleEdgeCreated } = this;
    let roughSvg = rough.svg(svg);

    let $svg = $(svg);

    // this is called after resize to the layout adjusts to the new size also
    $svg.empty();
    $svg.off();

    let canvasWidth = $svg.width(); // svg.clientWidth;
    let canvasHeight = $svg.height(); // svg.clientHeight;
    let canvasMinSize = Math.min(canvasWidth, canvasHeight);

    const camera = {
      scale: 1,
      translate: {
        x: 0,
        y: 0
      }
    }

    const defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");
    const style = document.createElementNS("http://www.w3.org/2000/svg", "style");

    style.type = "text/css";

    style.innerHTML = `
      @font-face {
        font-family: 'Kalam';
        src: url('/fonts/Kalam-Regular.ttf') format('truetype');
      }

      text {
        font-family: Kalam;
        font-size: 12px;
      }
    `

    defs.appendChild(style);
    svg.appendChild(defs);

    let sceneGroup = create('g');
    svg.appendChild(sceneGroup);

    let edgeLayer = this.edgeLayer = create('g');
    sceneGroup.appendChild(edgeLayer);

    // let graph = buildGraph();
    let { graph } = this.props;

    positionGraph(graph);

    drawGraph(graph);

    // Need to store the original scene bounds or zoom fit jitters on resize
    const sceneBounds = sceneGroup.getBoundingClientRect();

    zoomFit();
    applyCamera();

    let prevX;
    let prevY;
    let count = 1;

    let state = {
      dragging: false
    }

    if (!readonly) {
      addListeners()
    }

    function toWorldX(offsetX) {
      return (offsetX - camera.translate.x) / camera.scale;
    }

    function toWorldY(offsetY) {
      return (offsetY - camera.translate.y) / camera.scale;
    }

    function onEnterGroup($group) {
      let transform = $group.attr('transform');
      $group.attr('transform', `${transform} scale(1.5)`);
    }

    function onLeaveGroup($group) {
      let transform = $group.attr('transform');
      $group.attr('transform', `${transform.split('scale')[0]}`);
    }

    function addListeners() {
      $(svg)
        .on('mousemove touchmove', (event) => {
          // we have to handle enter/leave manually since we need to support mouse and touch
          let $currentTargetGroup = getTargetNodeGroup(event);

          if (state.$activeTargetGroup && state.$activeTargetGroup.length && !state.$activeTargetGroup.is($currentTargetGroup)) {
            onLeaveGroup(state.$activeTargetGroup);
          }

          if ($currentTargetGroup.length && !$currentTargetGroup.is(state.$activeTargetGroup)) {
            onEnterGroup($currentTargetGroup);
          }

          state.$activeTargetGroup = $currentTargetGroup;

          if (!state.dragging) return;

          // prevent scroll for touch devices
          event.preventDefault();

          let $node = getTargetNodeGroup(event);

          if ($node.length) {
            // snap to node center
            let nodeID = $node.attr('data-id');
            let node = graph.getNode(nodeID);
            state.dragEndX = node.x;
            state.dragEndY = node.y;
          } else {
            let { offsetX, offsetY } = event;

            if (event.type === 'touchmove') {
              let svgBounds = svg.getBoundingClientRect();
              offsetX = event.touches[0].pageX - svgBounds.left;
              offsetY = event.touches[0].pageY - svgBounds.top;
            }

            state.dragEndX = toWorldX(offsetX);
            state.dragEndY = toWorldY(offsetY);
          }

          updateDragEdge();
        })
        .on('mouseup touchend', (event) => {
          if (!state.dragging) return;
          finalizeDrag();
        })

      $(svg)
        .on('mousedown touchstart', '.node-hit', (event) => {
          if (state.dragging) return;

          let node = getTargetNode(event);
          state.dragging = true;
          state.dragSource = node;
          state.dragTarget = null;
          state.dragEdge = roughSvg.line(0, 0, 100, 0);
          // state.dragEdgeArrow = roughSvg.linearPath([[-10, 5], [2, 0], [-10, -5]]);
          state.dragStartX = state.dragEndX = node.x;
          state.dragStartY = state.dragEndY = node.y;

          updateDragEdge();

          edgeLayer.appendChild(state.dragEdge);
          // edgeLayer.appendChild(state.dragEdgeArrow);
        })
        .on('mouseup touchend', '.node-hit', (event) => {
          if (!state.dragging) return;
          state.dragTarget = getTargetNode(event);
          finalizeDrag();
        })
    }

    function getTargetNode(event) {
      let $group = getTargetNodeGroup(event);
      return graph.getNode($group.attr('data-id'));
    }

    function getTargetNodeGroup(event) {
      return $(getEventTarget(event)).closest('.node-group');
    }

    // event.target stays bound to original target for touchmove
    function getEventTarget(event) {
      if (event.type.indexOf('touch') === 0) {
        let { clientX } = event.originalEvent.changedTouches[0];
        let { clientY } = event.originalEvent.changedTouches[0];
        return document.elementFromPoint(clientX, clientY);
      } else {
        return event.target;
      }
    }

    function updateDragEdge() {
      let deltaX = state.dragEndX - state.dragStartX;
      let deltaY = state.dragEndY - state.dragStartY;
      let magnitude = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      let x = state.dragStartX;
      let y = state.dragStartY;
      let scale = magnitude / 100;
      let angleRadians = Math.atan2(deltaY / magnitude, deltaX / magnitude) || 0; // handle no length
      let angleDegrees = angleRadians * 180 / Math.PI;

      state.dragEdge.setAttribute('transform', `translate(${x} ${y}) rotate(${angleDegrees}) scale(${scale} 1)`);
    }

    // function updateDragEdgeRealistic() {
    //   let deltaX = state.dragEndX - state.dragStartX;
    //   let deltaY = state.dragEndY - state.dragStartY;
    //   let magnitude = Math.sqrt(deltaX * deltaX + deltaY * deltaY) - NODE_RADIUS;
    //   let unitX = deltaX / magnitude;
    //   let unitY = deltaY / magnitude;
    //   let x = state.dragStartX;
    //   let y = state.dragStartY;
    //   let scale = Math.max(0, (magnitude - 2 * NODE_RADIUS) / 100);
    //   let angleRadians = Math.atan2(deltaY / magnitude, deltaX / magnitude);
    //   let angleDegrees = angleRadians * 180 / Math.PI;
    //
    //   let lineX0 = state.dragStartX + unitX * NODE_RADIUS * 2;
    //   let lineY0 = state.dragStartY + unitY * NODE_RADIUS * 2;
    //   let lineX1 = state.dragStartX + unitX * (magnitude - 2 * NODE_RADIUS);
    //   let lineY1 = state.dragStartY + unitY * (magnitude - 2 * NODE_RADIUS);
    //
    //   state.dragEdge.setAttribute('transform', `translate(${lineX0} ${lineY0}) rotate(${angleDegrees}) scale(${scale} 1)`);
    //   state.dragEdgeArrow.setAttribute('transform', `translate(${lineX1} ${lineY1}) rotate(${angleDegrees})`);
    // }

    function positionGraph(graph) {
      let { focusNode, innerNodes, outerNodes } = graph;

      let innerRadius = canvasMinSize / 4.5;
      let outerRadius = canvasMinSize / 2;

      let innerDelta = 2 * Math.PI / innerNodes.length;
      let outerDelta = 2 * Math.PI / outerNodes.length;

      // Start at midnight and move clockwise
      let innerBasis = -Math.PI / 2; // midnight
      let outerBasis = -Math.PI / 2; // + innerDelta / 2;

      focusNode.x = 0;
      focusNode.y = 0;

      function positionNode(node, index, radius, basis, delta) {
        let angle = basis + delta * index;
        node.x = radius * Math.cos(angle);
        node.y = radius * Math.sin(angle);
      }

      innerNodes.forEach((node, index) => {
        positionNode(node, index, innerRadius, innerBasis, innerDelta);
      })

      outerNodes.forEach((node, index) => {
        positionNode(node, index, outerRadius, outerBasis, outerDelta);
      })

      return graph;
    }

    function create(nodeType) {
      return document.createElementNS('http://www.w3.org/2000/svg', nodeType);
    }

    function drawGraph(graph) {
      graph.nodes.forEach(drawNode);
      graph.edges.forEach(drawEdge);
    }

    function drawNode(node) {
      let { id, label, x, y } = node;
      let radius = NODE_RADIUS;

      let nodeGroup = create('g');
      nodeGroup.setAttribute('data-id', id);
      nodeGroup.setAttribute('class', 'node-group');
      nodeGroup.setAttribute('transform', `translate(${x} ${y})`);

      let nodeHit = create('circle');
      nodeHit.setAttribute('class', 'node-hit');
      nodeHit.setAttribute('cx', 0);
      nodeHit.setAttribute('cy', 0);
      nodeHit.setAttribute('r', 2 * radius); // large to make it easier to hit on touch devices
      nodeHit.setAttribute('fill', 'transparent');
      nodeGroup.appendChild(nodeHit);

      let nodeFill = create('circle');
      nodeFill.setAttribute('cx', 0);
      nodeFill.setAttribute('cy', 0);
      nodeFill.setAttribute('r', radius);
      nodeFill.setAttribute('fill', '#fff');
      nodeGroup.appendChild(nodeFill);

      let circle = roughSvg.circle(0, 0, radius * 2);
      nodeGroup.appendChild(circle);

      let [ line1, line2 ] = splitString(label);
      if (line1) nodeGroup.appendChild(drawText(line1, 0, radius, 'top'));
      if (line2) nodeGroup.appendChild(drawText(line2, 0, radius + 14, 'top'));

      sceneGroup.appendChild(nodeGroup);
    }

    function drawEdge(edge) {
      let { from, to } = edge;
      let edgeGroup = create('g');

      let x0 = from.x;
      let y0 = from.y;
      let x1 = to.x;
      let y1 = to.y;

      let deltaX = x1 - x0;
      let deltaY = y1 - y0;
      let magnitude = Math.sqrt(deltaX * deltaX + deltaY * deltaY) - NODE_RADIUS;
      let unitX = deltaX / magnitude;
      let unitY = deltaY / magnitude;

      let lineX0 = x0 + unitX * NODE_RADIUS * 2;
      let lineY0 = y0 + unitY * NODE_RADIUS * 2;

      let lineX1 = x0 + unitX * (magnitude - 2 * NODE_RADIUS);
      let lineY1 = y0 + unitY * (magnitude - 2 * NODE_RADIUS);
      let line = roughSvg.line(lineX0, lineY0, lineX1, lineY1);
      edgeGroup.appendChild(line);

      let angleRadians = Math.atan2(deltaY / magnitude, deltaX / magnitude);
      let angleDegrees = angleRadians * 180 / Math.PI;

      let arrow = roughSvg.linearPath([[-10, 5], [2, 0], [-10, -5]]);
      arrow.setAttribute('transform', `translate(${lineX1} ${lineY1}) rotate(${angleDegrees})`);
      edgeGroup.appendChild(arrow);

      edgeLayer.appendChild(edgeGroup);

      return edgeGroup;
    }

    function finalizeDrag() {
      let { dragSource, dragTarget } = state;

      state.dragging = false;

      edgeLayer.removeChild(state.dragEdge);
      // edgeLayer.removeChild(state.dragEdgeArrow);

      // incomplete drag
      if (!dragSource || !dragTarget || dragSource === dragTarget) return;

      // no redundant edges
      if (graph.edges.some(edge => edge.from === dragSource && edge.to === dragTarget)) return;

      let from = dragSource;
      let to = dragTarget;

      let edge = { from, to };
      graph.edges.push(edge);

      let edgeSvg = drawEdge(edge);

      handleEdgeCreated(edge, edgeSvg);
    }

    // double rendered for a nice thick outside outline
    function drawText(value, x, y, anchor) {
      let group = create('g');
      group.setAttribute('transform', `translate(${x} ${y})`);

      let shadow = create('text');
      shadow.textContent = value;
      shadow.setAttribute('fill', '#000');
      shadow.setAttribute('stroke', '#fff');
      shadow.setAttribute('stroke-width', 4);
      shadow.setAttribute('text-anchor', 'middle');

      let fill = create('text');
      fill.textContent = value;
      fill.setAttribute('fill', '#000');
      fill.setAttribute('text-anchor', 'middle');

      // Using dy since dominant-baseline isn't supported in IE11
      if (anchor === 'top') {
        shadow.setAttribute('dy', 10);
        fill.setAttribute('dy', 10);
      }

      group.appendChild(shadow);
      group.appendChild(fill);

      return group;
    }

    function zoomFit() {
      // svg.clientWidth and svg.clientHeight return 0 in firefox so we used jquery instead
      let $svg = $(svg);
      let canvasWidth = $svg.width();
      let canvasHeight = $svg.height();
      let widthScale = canvasWidth / sceneBounds.width;
      let heightScale = canvasHeight / sceneBounds.height;
      let zoomFitScale = 0.9 * Math.min(widthScale, heightScale)
      camera.scale = Math.min(1, zoomFitScale);
      camera.translate.x = canvasWidth / 2;

      // fudged since scene isn't vertically centered
      camera.translate.y = canvasHeight / 2 - NODE_RADIUS;
    }

    function applyCamera() {
      let { scale, translate } = camera;
      sceneGroup.setAttribute('transform', `translate(${translate.x} ${translate.y}) scale(${scale})`);
    }

    this.actions.resize = () => {
      // resize the SVG container to fill the screen
      // (workaround for ios address bar hiding shenanigans)
      this.resizeSVG();

      // rerender so layout adjusts to new size
      this.renderSVG();

      // fill the screen
      zoomFit();

      // center the scene
      applyCamera();
    };
  }

  handleResize = () => {
    this.actions.resize();
  }

  handleEdgeCreated = (edge, edgeSvg) => {
    this.history.push(edge);
    this.props.onEdgeCreated(edge);
  }

  handleKeyDown = (event) => {
    if (event.key == "z" && (event.ctrlKey || event.metaKey)) { // ctrl for IE11
      event.preventDefault();

      if (event.shiftKey) {
        this.actions.redo();
      } else {
        this.actions.undo();
      }
    }
  }
}

export default ConnectionEditor;
