import React from 'react';

const Logo = (props) => {
  let color = props.color || '#000';

  // style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">

  return (
    <svg className="logo" viewBox="0 0 492 492" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g fill={color} transform="matrix(0,-1.38545,1.38545,0,-0.997788,492.496)">
        <path d="M355.478,20.72C355.478,9.675 346.523,0.72 335.478,0.72L213.762,0.72C209.62,0.72 206.262,4.078 206.262,8.22L206.262,33.22C206.262,37.362 209.62,40.72 213.762,40.72L269.087,40.72C275.768,40.72 279.115,48.799 274.39,53.524L183.281,144.633C180.352,147.562 175.603,147.562 172.674,144.633L81.565,53.524C76.84,48.799 80.187,40.72 86.868,40.72L142.193,40.72C146.335,40.72 149.693,37.362 149.693,33.22L149.693,8.22C149.693,4.078 146.335,0.72 142.193,0.72L20.478,0.72C9.432,0.72 0.478,9.675 0.478,20.72L0.478,142.436C0.478,146.578 3.835,149.936 7.978,149.936L32.978,149.936C37.12,149.936 40.478,146.578 40.478,142.436L40.478,87.111C40.478,80.429 48.556,77.083 53.281,81.808L144.39,172.917C147.319,175.846 147.319,180.595 144.39,183.523L53.281,274.633C48.556,279.357 40.478,276.011 40.478,269.329L40.478,214.004C40.478,209.862 37.12,206.504 32.978,206.504L7.978,206.504C3.835,206.504 0.478,209.862 0.478,214.004L0.478,335.72C0.478,346.766 9.432,355.72 20.478,355.72L142.193,355.72C146.335,355.72 149.693,352.362 149.693,348.22L149.693,323.22C149.693,319.078 146.335,315.72 142.193,315.72L86.869,315.72C80.187,315.72 76.84,307.642 81.565,302.917L172.674,211.808C175.603,208.879 180.352,208.879 183.281,211.808L274.39,302.917C279.115,307.642 275.768,315.72 269.087,315.72L213.762,315.72C209.62,315.72 206.262,319.078 206.262,323.22L206.262,348.22C206.262,352.362 209.62,355.72 213.762,355.72L335.478,355.72C346.523,355.72 355.478,346.766 355.478,335.72L355.478,214.004C355.478,209.862 352.12,206.504 347.978,206.504L322.978,206.504C318.835,206.504 315.478,209.862 315.478,214.004L315.478,269.329C315.478,276.011 307.399,279.357 302.674,274.633L211.565,183.523C208.636,180.595 208.636,175.846 211.565,172.917L302.674,81.808C307.399,77.083 315.478,80.429 315.478,87.111L315.478,142.436C315.478,146.578 318.835,149.936 322.978,149.936L347.978,149.936C352.12,149.936 355.478,146.578 355.478,142.436L355.478,20.72Z" />
      </g>
    </svg>
  )
}

export default Logo;
