import React from 'react';
import classNames from 'classnames';
import { Layout, Menu, Tabs, Spin, Icon, message } from 'antd';
import { AppState } from '../types';
import Logo from './Logo';
import MappingsEditor from './MappingsEditor';
import MasterMatrix from './MasterMatrix';
import AnalyticsView from './AnalyticsView';
import ResponsesView from './ResponsesView';
import SurveyAdminView from './SurveyAdminView';
import './SurveyView.css';

const { Header, Content, Footer, Sider } = Layout;
const { TabPane } = Tabs;

type SurveyViewProps = {
  app: AppState;
}

type SurveyViewState = {
  location: string;
  dragging: boolean;
}

class SurveyView extends React.Component<SurveyViewProps> {
  state = {
    location: '',
    dragging: false
  }

  componentWillMount() {
    let { actions } = this.props.app;
    actions.fetchSurveyData();
  }

  render() {
    let { app } = this.props;
    let { ready, survey, surveyData } = app.state;
    let { location, dragging } = this.state;
    let content;

    if (!ready) return this.renderLoading();

    if (!location || location === 'master') {
      content = <MappingsEditor app={app} />;
    } else if (location === 'matrix') {
      content = <MasterMatrix app={app} />;
    } else if (location === 'analytics') {
      content = <AnalyticsView app={app} />;
    } else if (location === 'responses') {
      content = <ResponsesView app={app} />;
    } else if (location === 'admin') {
      content = <SurveyAdminView app={app} />;
    }

    let className = classNames('survey-view', { dragging });

    return (
      <div className={className}
       onDragEnter={this.handleDragEnter}
       onDragOver={this.handleDragOver}
       onMouseMove={this.handleMouseMove}
       onDrop={this.handleDrop}
       >
        <div className='import-drop-target'>
          <p>drop xlsx/csv here to import</p>
        </div>

        <Layout>
          <Sider
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
              left: 0,
            }}
          >
            <Logo color='#fff' />

            <h3 className='survey-name'>{survey.name}</h3>

            <Menu theme="dark" mode="inline" onClick={this.handleMenuClick} defaultSelectedKeys={['master']}>
              <Menu.Item key="master">
                <Icon type="edit" />
                <span className="nav-text">Master values</span>
              </Menu.Item>
              <Menu.Item key="matrix">
                <Icon type="table" />
                <span className="nav-text">Master matrix</span>
              </Menu.Item>
              <Menu.Item key="analytics">
                <Icon type="dot-chart" />
                <span className="nav-text">Analytics</span>
              </Menu.Item>
              <Menu.Item key="responses">
                <Icon type="unordered-list" />
                <span className="nav-text">Responses</span>
              </Menu.Item>
              <Menu.Item key="admin">
                <Icon type="setting" />
                <span className="nav-text">Admin</span>
              </Menu.Item>
              <Menu.Item key="dashboard">
                <a href="#/admin"><Icon type="left" /> Back to dashboard</a>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout style={{ background: '#fff', paddingLeft: 200 }}>
            {/*<Header style={{ background: '#fff', padding: 0 }} />*/}
            <Content style={{ background: '#fff' }}>{content}</Content>
            {/*<Footer style={{ background: '#fff', textAlign: 'center' }}>System Effects © 2019</Footer>*/}
          </Layout>
        </Layout>
      </div>
    )
  }

  renderLoading() {
    return <div style={{marginTop: '10vh', textAlign: 'center'}}><Spin size="large" /></div>;
  }

  handleMenuClick = (event) => {
    this.setState({location: event.key});
  }

  handleDrop = (event) => {
    event.preventDefault();

    if (event.dataTransfer.files) {
      let { app } = this.props;

      Array.from(event.dataTransfer.files).forEach((file:File) => {
        if (!/(xlsx|csv)$/.test(file.name)) {
          message.error(`Unable to import ${file.name}. Only xlsx and csv imports are supported at this time.`);
          return;
        }

        app.actions.importResponse(file)
          .then(() => message.success(`Successfully imported ${file.name}`))
          .catch(error => {
            console.error(error);
            message.error(`Unable to import ${file.name}`);
          })
      })
    }

    this.setState({dragging: false});
  }

  handleDragEnter = (event) => {
    this.setState({dragging: true});
  }

  handleDragOver = (event) => {
    // Without these the onDrop event won't fire
    // https://stackoverflow.com/questions/50230048/react-ondrop-is-not-firing/51437194#51437194
    event.preventDefault();
    event.stopPropagation();
  }

  handleMouseMove = (event) => {
    if (this.state.dragging && !this.isFileDrop(event)) {
      this.setState({dragging: false});
    }
  }

  isFileDrop(event) {
    return Boolean(event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0]);
  }
}

export default SurveyView;
