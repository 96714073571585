import React from 'react';

class GDPR extends React.Component {
  render() {
    return (
      <div className='legal'>
        <div className='container'>
          <img className="logo" src="/images/logo.png" alt="System Effects logo" />

          <h1>GDPR Policy</h1>

          <p>
            We offer a data processing addendum (DPA) for our customers who are
            working with data from people in the EU that is regulated by GDPR
            and does not fall under one of the specific processing exemptions.
            Our DPA offers contractual terms that satisfy GDPR requirements and
            reflect our data privacy and security commitments to our customers.
            Please email privacy@kumu.io to request our DPA.
          </p>

          <p>
            To guarantee no terms are imposed on us beyond what is reflected in
            our DPA and Terms of Service, we cannot agree to sign individual
            customers' DPAs. Doing so would require legal counsel and
            considerable back and forth discussion, which would be
            cost-prohibitive for our small team.
          </p>

          <p>
            For more information on our privacy practices, please consult our
            Privacy Policy.
          </p>

          <h2>Subprocessors</h2>

          <p>
            Kumu has authorized the subprocessors on this list to process
            personal data so we can provide the System Effects website and
            services. Prior to engaging with any subprocessor, Kumu performs due
            diligence to evaluate their privacy and security practices, and
            requires each to commit to written obligations regarding their
            security controls and obligations.
          </p>

          <p>
            Our business needs may change from time to time. We will periodically
            update this page to reflect additions and removals to our list of
            subprocessors.
          </p>

          <ul>
            <li>Amazon Web Services</li>
            <li>Baremetrics</li>
            <li>ConvertKit</li>
            <li>ChartMogul</li>
            <li>Google</li>
            <li>Front</li>
            <li>Slack</li>
            <li>Stripe</li>
            <li>Twilio</li>
            <li>Zapier</li>
            <li>Xero</li>
          </ul>

          <p>
            For more information on our privacy practices, please consult our
            Privacy Policy.
          </p>
        </div>
      </div>
    )
  }
}

export default GDPR;
