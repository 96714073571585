import sortBy from 'lodash/sortBy';
import graphology from 'graphology';
import pagerank from 'graphology-pagerank';
import { Matrix } from '../../types';
import { getEdgeListFromMatrix } from '../../util';

export function calculateMetrics(matrix: Matrix) {
  let list = [];
  let pageranks = calculatePagerank(matrix);

  matrix.labels.forEach((label, index) => {
    // ignore focus
    if (index === 0) return;

    let metrics = {
      orphan: false,
      degree: 0,
      indegree: calculateIndegree(matrix, index),
      outdegree: calculateOutdegree(matrix, index),
      pagerank: pageranks[label]
    }

    metrics.degree = metrics.indegree + metrics.outdegree;
    metrics.orphan = metrics.degree === 0;

    // omit irrelevant factors (has to be done here otherwise the orphans
    // will throw off the ranks below)
    if (metrics.orphan) return;

    list.push({ label, ...metrics });
  })

  assignRank(list, "indegree");
  assignRank(list, "outdegree");
  assignRank(list, "pagerank");

  return list;
}

// indegree sum of column
function calculateIndegree(matrix, index) {
  let indegree = 0;

  for (let i = 0; i < matrix.labels.length; i++) {
    indegree += matrix.data[i][index]
  }

  return indegree;
}

// outdegree sum of row
function calculateOutdegree(matrix, index) {
  let outdegree = 0;

  for (let j = 0; j < matrix.labels.length; j++) {
    outdegree += matrix.data[index][j]
  }

  return outdegree;
}

// look into transition matrix
// http://www.ccs.neu.edu/home/daikeshi/notes/PageRank.pdf
function calculatePagerank(matrix) {
  let edges = getEdgeListFromMatrix(matrix);
  let graph = new graphology.DirectedGraph();

  edges.forEach(({ from, to, weight }) => {
    graph.mergeEdge(from, to, { weight });
  })

  return pagerank(graph, {weighted: true});
}

function assignRank(list, metric) {
  sortBy(list.slice(), d => d[metric]).forEach((d, i) => d[`${metric} rank`] = i);
}
