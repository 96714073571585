import './MasterMatrix.css';

import download from 'downloadjs';
import React from 'react';
import { Button } from 'antd';
import { AppState, Response, TranslationOptions } from '../types';
import FilterInput from './FilterInput';
import AdjacencyMatrix from './AdjacencyMatrix';
import { getAggregateMatrixFromResponses, filterResponses, matrixToCSV, getBlueprintFromMatrix } from '../util';

type Props = {
  app: AppState;
}

type State = {
  responses: Response[];
  categorize: boolean;
}

class MasterMatrix extends React.Component<Props, State> {
  state = {responses: [], categorize: false}

  componentWillMount() {
    this.updateFilter(null);
  }

  getMatrix() {
    let { app } = this.props;
    let { survey, surveyData } = app.state;
    let { responses, categorize } = this.state;
    let translationOptions: TranslationOptions = { categorize };
    let matrix = getAggregateMatrixFromResponses(survey, surveyData, responses, translationOptions);

    return matrix;
  }

  render() {
    let { app } = this.props;
    let { categoriesEnabled } = app.state;
    let { responses } = this.state;

    return (
      <div className='master-matrix'>
        <div className='master-matrix-header'>
          <FilterInput onChange={this.handleFilterChange} />

        {categoriesEnabled &&
          <div>
            <input type='radio' name='categorizeToggle' value='0' onChange={() => this.setState({categorize: false})} defaultChecked /> Uncategorized{' '}
            <input type='radio' name='categorizeToggle' value='1' onChange={() => this.setState({categorize: true})} /> Categorized
          </div>
        }
        </div>

        {responses.length === 0 ? this.renderEmpty() : this.renderMatrix()}
      </div>
    )
  }

  renderEmpty() {
    return <p>No responses found</p>;
  }

  renderMatrix() {
    let matrix = this.getMatrix();

    return (
      <div>
        <AdjacencyMatrix matrix={matrix} isMaster />

        <p className='text-center'>
          <Button.Group>
            <Button onClick={this.handleDownloadMatrix}>Download matrix</Button>
            <Button onClick={this.handleDownloadBlueprint}>Download Kumu blueprint</Button>
          </Button.Group>
        </p>
      </div>
    )
  }

  updateFilter(filter) {
    let { app } = this.props;
    let { surveyData } = app.state;
    let responses = filterResponses(surveyData.responses, filter);

    this.setState({ responses });
  }

  handleDownloadMatrix = () => {
    let { survey } = this.props.app.state;
    let csv = matrixToCSV(this.getMatrix());
    download(csv, `${survey.name}.csv`, 'text/csv');
  }

  handleDownloadBlueprint = () => {
    let { survey } = this.props.app.state;
    let blueprint = getBlueprintFromMatrix(this.getMatrix());
    let json = JSON.stringify(blueprint, null, '  ');
    download(json, `${survey.name}.json`, '	application/json');
  }

  handleFilterChange = (filter) => {
    this.updateFilter(filter);
  }
}

export default MasterMatrix;
