import React from 'react';
import { Alert, Icon } from 'antd';
import { Survey, SurveyData, Response } from '../types';
import {
  getUniqueValuesFromResponse,
  getResponseTranslationStats
} from '../util';

import './ResponseTranslationTable.css';

type TableProps = {
  survey: Survey;
  surveyData: SurveyData;
  response: Response;
}

class ResponseTranslationTable extends React.Component<TableProps> {
  render() {
    let { survey, surveyData, response } = this.props;
    let data = getResponseTranslationStats(survey, surveyData, response);

    return (
      <div className='response-translation-table'>
        <br/><br/>
        <h4>Translated values</h4>
        {data.mappings.map(this.renderMapping)}

        <br/><br/>
        <h4>Translated connections</h4>
        {data.edges.map(this.renderEdge)}
      </div>
    )
  }

  renderMapping(mapping) {
    let [ raw, master ] = mapping;
    let icon = master
      ? <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
      : <Icon type="close-circle" theme="twoTone" twoToneColor="#ff0000" />

    return (
      <div className='item'>
        <div className='raw'>{raw}</div>
        {icon}
        <div className='master'>{master}</div>
      </div>
    )
  }

  renderEdge(edge) {
    return <div>{edge.from} <Icon type="arrow-right" /> {edge.to}</div>;
  }
}

export default ResponseTranslationTable;
