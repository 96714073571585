import React from 'react';
import { Input, message } from 'antd';
import { AppState, Response } from '../types';

type Props = {
  app: AppState;
  response: Response;
}

class TagInput extends React.Component<Props> {
  render() {
    let { response } = this.props;
    return <Input defaultValue={response.tags} placeholder='Response tags' onBlur={this.handleBlur} />;
  }

  handleBlur = (event) => {
    let { app, response } = this.props;
    let tags = event.target.value;

    app.actions.updateResponseTags(response.id, tags)
      .then(() => message.success('Response tags updated'))
      .catch((error) => {
        console.error(error);
        message.error('Unable to update response tags');
      })
  }
}

export default TagInput;
