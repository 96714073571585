import React from 'react';
import { Form, Input, Button, message } from 'antd';
import Centered from '../survey/Centered';
import db from '../db';
import { AppState, Session } from '../types';

type Props = {
  onLogin: Function;
}

type State = {
  email: string;
}

class LoginScreen extends React.Component<Props, State> {
  emailRef: any;
  tokenRef: any;

  constructor(props) {
    super(props);

    this.emailRef = React.createRef();
    this.tokenRef = React.createRef();

    this.state = {email: null};
  }

  render() {
    let { email } = this.state;
    let form = email ? this.renderTokenForm() : this.renderEmailForm();

    return (
      <div>
        <Centered>
          <div className='text-center'>
            <h3>System Effects</h3>
            {form}
          </div>
        </Centered>
      </div>
    )
  }

  renderEmailForm() {
    return (
      <Form onSubmit={this.handleSubmitEmail} className="login-form">
        <Form.Item>
          <input ref={this.emailRef} type='text' className='ant-input' placeholder='Email' />
        </Form.Item>

        {/*<Form.Item>
          <input ref={this.passwordRef} type='password' className='ant-input' placeholder='Password' />
        </Form.Item>*/}

        <Form.Item>
          <button className='ant-btn ant-btn-primary'>Sign in</button>
        </Form.Item>
      </Form>
    )
  }

  renderTokenForm() {
    return (
      <Form onSubmit={this.handleSubmitToken} className="login-form">
        <p>Please check your inbox for your sign-in code.</p>

        <Form.Item>
          <input ref={this.tokenRef} type='text' className='ant-input' placeholder='Code' size={12} style={{width: 'auto', textAlign: 'center'}} autoFocus />
        </Form.Item>

        <Form.Item>
          <button className='ant-btn ant-btn-primary'>Submit</button>
        </Form.Item>
      </Form>
    )
  }

  handleSubmitEmail = async (event) => {
    event.preventDefault();

    let email = this.emailRef.current.value;

    try {
      this.setState({ email });
      await db.authenticate(email);
    } catch (error) {
      this.setState({ email: null });
      message.warning((error && error.error) || 'Unable to sign in. Please try again.');
    }
  }

  handleSubmitToken = async (event) => {
    event.preventDefault();

    let { email } = this.state;
    let token = this.tokenRef.current.value;

    try {
      let session: Session = await db.authenticate(email, token);
      this.props.onLogin(session);
    } catch (error) {
      message.warning((error && error.error) || 'Unable to sign in. Please try again.');
    }
  }
}

export default LoginScreen;
