import React from 'react';
import RadioInput from './RadioInput';
import CheckboxInput from './CheckboxInput';
import SelectInput from './SelectInput';
import TextInput from './TextInput';

const INPUTS = {
  radio: RadioInput,
  checkbox: CheckboxInput,
  select: SelectInput,
  text: TextInput,
}

export default function renderInput(type, props) {
  let Input = INPUTS[type];
  return <Input {...props} />;
}
