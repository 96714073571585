import React from 'react';
import { Input } from 'antd';

class TextInput extends React.Component {
  render() {
    let { placeholder } = this.props;
    return <Input placeholder={placeholder} onChange={this.handleChange} />;
  }

  handleChange = (event) => {
    this.props.onChange(event.target.value);
  }
}

export default TextInput;
