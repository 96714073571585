import $ from 'jquery';
import React from 'react';
import { Icon, Button, Input, Modal } from 'antd';
import { AppState } from '../types';

type Props = {
  app: AppState;
}

const AdminView = ({ app, email }) => {

}

class SurveyAdminsForm extends React.Component<Props> {
  render() {
    let { app } = this.props;
    let { surveyData } = app.state;

    return (
      <div>
        <h3>Admins</h3>
        <ul>
          {surveyData.admins.map(this.renderAdmin)}
          <li>
            <form onSubmit={this.handleAddAdmin}>
              <input type='email' className='new-admin-input ant-input' placeholder='admin@example.com' style={{width: 200}} />
            </form>
          </li>
        </ul>
      </div>
    )
  }

  renderAdmin = (email) => {
    let { app } = this.props;

    const dropAdmin = () => {
      Modal.confirm({
        title: 'Revoke project access',
        content: `Are you sure you want to drop ${email} from the project?`,
        onOk: () => {
          app.actions.dropAdmin(email);
        }
      })
    }

    return <li key={email}>{email} <Icon type='stop' onClick={dropAdmin} /></li>;
  }

  handleAddAdmin = (event) => {
    event.preventDefault();

    let { app } = this.props;
    let $input = $('.new-admin-input');
    let email = $input.val();

    app.actions.addAdmin(email);

    $input.val('');
  }
}

export default SurveyAdminsForm;
