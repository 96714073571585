import React from 'react';
import { Row, Col, Select, Input, Button, Icon, Tooltip, message } from 'antd';
import download from 'downloadjs';
import { AppState } from '../types';
import ResponseView from './ResponseView';
import {
  getOriginalMatrix,
  getTranslatedMatrixFromResponse,
  getMasterMatrixFromResponse,
  matrixToCSV
} from '../util';

import './ResponsesView.css';

const Option = Select.Option;

type ResponsesViewProps = {
  app: AppState;
}

class ResponsesView extends React.Component<ResponsesViewProps> {
  state = {
    currentResponseIndex: 0
  }

  getCurrentResponse() {
    let { currentResponseIndex } = this.state;
    return this.props.app.state.surveyData.responses[currentResponseIndex];
  }

  render() {
    let { app } = this.props;
    let { survey, surveyData } = app.state;
    let { currentResponseIndex } = this.state;
    let currentResponse = this.getCurrentResponse();

    const getResponseLabel = (response, index) => {
      let label = `Response ${index + 1}`;
      let meta = [];
      if (response.tags) meta.push(response.tags);
      if (response.email) meta.push(response.email);
      if (meta.length) label += ` (${meta.join(' ')})`;
      return response.ignored ? <del>{label}</del> : label;
    }

    return (
      <div className='responses-view'>
        <div className='responses-view-header'>
          <Input.Group compact>
            <Button onClick={this.handlePrev}><Icon type="left" /></Button>
            <Select value={currentResponseIndex} onChange={this.handleResponseSelect}>
              {surveyData.responses.map((response, i) => (
                <Option value={i}>{getResponseLabel(response, i)}</Option>
              ))}
            </Select>
            <Button onClick={this.handleNext}><Icon type="right" /></Button>
          </Input.Group>

          {currentResponse && (
            <div>
              <Button.Group>
                <Tooltip title="Download original csv">
                  <Button onClick={this.handleDownloadOriginal}><Icon type="download" /> Original</Button>
                </Tooltip>

                <Tooltip title="Download translated csv">
                  <Button onClick={this.handleDownloadTranslated}><Icon type="download" /> Translated</Button>
                </Tooltip>

                <Tooltip title="Download master csv">
                  <Button onClick={this.handleDownloadMaster}><Icon type="download" /> Master</Button>
                </Tooltip>
              </Button.Group>

              <Button.Group>
                <Tooltip title={currentResponse.ignored ? 'Response ignored. Click to restore.' : 'Ignore response'}>
                  <Button onClick={this.handleIgnore}><Icon type='dislike' theme={currentResponse.ignored ? 'filled' : undefined} /> Ignore response</Button>
                </Tooltip>
              </Button.Group>
            </div>
          )}
        </div>

        {currentResponse && <ResponseView app={app} response={currentResponse} />}
      </div>
    )
  }

  handleResponseSelect = (index) => {
    this.setState({currentResponseIndex: index});
  }

  handlePrev = () => {
    let { currentResponseIndex } = this.state;
    if (currentResponseIndex === 0) return;
    this.setState({currentResponseIndex: currentResponseIndex - 1});
  }

  handleNext = () => {
    let { app } = this.props;
    let { surveyData } = app.state;
    let { currentResponseIndex } = this.state;
    if (currentResponseIndex === surveyData.responses.length - 1) return;
    this.setState({currentResponseIndex: currentResponseIndex + 1});
  }

  handleDownloadOriginal = (event) => {
    let { survey, surveyData } = this.props.app.state;
    let response = this.getCurrentResponse();
    let matrix = getOriginalMatrix(survey, response);
    let csv = matrixToCSV(matrix);

    download(csv, `${survey.name} ${response.id} (original).csv`, 'text/csv');
  }

  handleDownloadTranslated = (event) => {
    let { survey, surveyData } = this.props.app.state;
    let response = this.getCurrentResponse();
    let matrix = getTranslatedMatrixFromResponse(survey, surveyData, response);
    let csv = matrixToCSV(matrix);

    download(csv, `${survey.name} ${response.id} (translated).csv`, 'text/csv');
  }

  handleDownloadMaster = (event) => {
    let { survey, surveyData } = this.props.app.state;
    let response = this.getCurrentResponse();
    let matrix = getMasterMatrixFromResponse(survey, surveyData, response);
    let csv = matrixToCSV(matrix);

    download(csv, `${survey.name} ${response.id} (master).csv`, 'text/csv');
  }

  handleIgnore = () => {
    if (window.confirm('Are you sure you want to ignore this response?')) {
      let response = this.getCurrentResponse();
      let ignore = !response.ignored;

      this.props.app.actions.ignoreResponse(response.id, ignore);

      if (ignore) {
        message.info('Response ignored');
      } else {
        message.info('Response restored');
      }
    }
  }
}

export default ResponsesView;
