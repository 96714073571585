import _ from "lodash";
import { SurveyData } from '../types';
import { uuid } from '../util';

// TODO: Pobably want to translate server side instead but haven't found a
// good solution yet. Maybe ActiveModel::Serializer or fast_jsonapi?
// https://thecodeboss.dev/2017/02/building-a-json-api-with-rails-part-6-the-json-api-spec-pagination-and-versioning/
const rails = {
  survey: function(survey) {
    let { id, name, settings, created_at, updated_at } = survey;
    return {id, name, ...settings, created: created_at, updated: updated_at};
  },

  response: function(response) {
    return response;
  },

  masterValue: function(masterValue) {
    if (masterValue.hasOwnProperty('key')) {
      masterValue.id = masterValue.key;
      delete masterValue.key;
    }

    return masterValue;
  }
}

class DB {
  token: string;

  constructor() {
    const config = {};
    this.token = '';
  }

  async get(path) {
    return this.fetch(path);
  }

  async create(path, data) {
    return this.fetch(path, {method: 'POST', data})
  }

  async update(path, data) {
    return this.fetch(path, {method: 'PATCH', data});
  }

  async destroy(path, data?) {
    return this.fetch(path, {method: 'DELETE', data});
  }

  // TODO: how do we handle invalid / expired tokens?
  async fetch(path, options: any = {}) {
    options.headers = options.headers || {};
    options.headers['Content-Type'] = 'application/json';

    if (this.token) {
      options.headers['Authorization'] = `Bearer ${this.token}`;
    }

    if (options.data) {
      let { data } = options;
      options.body = JSON.stringify(data);
      delete options.data;
    }

    // TODO: how do we want to handle errors? response.ok response.status
    let response = await fetch(path, options);
    let json = await response.json();

    if (!response.ok) {
      throw json;
    }

    return json;
  }

  async authenticate(email: string, token?: string) {
    if (token) {
      let sessionData = await this.create('/api/sessions', {email, token});
      return sessionData;
    } else {
      return await this.create('/api/sessions', {email});
    }
  }

  // TODO: realtime support for survey CRUD ops?
  async getSurveys() {
    let surveys = await this.get('/api/surveys');
    surveys = surveys.map(survey => rails.survey(survey))
    return surveys;
  }

  async getSurvey(id) {
    let survey = await this.get(`api/surveys/${id}`);
    return rails.survey(survey);
  }

  async createSurvey(attrs) {
    let { name, ...settings } = attrs;
    let survey = await this.create('/api/surveys', {survey: { name, settings }});
    return rails.survey(survey);
  }

  // TODO: this no longer updates the local survey state since we don't have the firebase hooks
  async updateSurvey(id, attrs) {
    let { name, ...settings } = attrs;
    let survey = await this.update(`/api/surveys/${id}`, {survey: { name, settings }});
    return rails.survey(survey);
  }

  async deleteSurvey(id) {
    return await await this.destroy(`/api/surveys/${id}`);
  }

  async importResponse(surveyID) {}

  // TODO: separate from importResponse helper?
  async createResponse(surveyID, response) {
    let savedResponse = await this.create(`/api/surveys/${surveyID}/responses`, { response });
    return savedResponse;
  }

  async updateResponse(responseID, updates) {
    let savedResponse = await this.update(`/api/responses/${responseID}`, {response: updates});
    return savedResponse;
  }

  async fetchSurveyData(surveyID): Promise<SurveyData> {
    let surveyData = await this.get(`/api/surveys/${surveyID}/data`);
    surveyData.responses = surveyData.responses.map(response => rails.response(response));
    surveyData.masterValues = surveyData.masterValues.map(master => rails.masterValue(master));
    return surveyData;
  }

  async createMasterValue(surveyID, id, value) {
    let masterValue = await this.create(`/api/surveys/${surveyID}/master_values`, {master_value: {key: id, value}});
    return rails.masterValue(masterValue);
  }

  async updateMasterValue(surveyID, id, attrs = {}) {
    let masterValue = await this.update(`/api/surveys/${surveyID}/master_values/${id}`, {master_value: attrs});
    return rails.masterValue(masterValue);
  }

  async deleteMasterValue(surveyID, id) {
    return await this.destroy(`/api/surveys/${surveyID}/master_values/${id}`);
  }

  async updateAlias(surveyID, value, masterID) {
    return await this.update(`/api/surveys/${surveyID}/alias`, {value, master: masterID});
  }

  async resetMasterValues(surveyID) {
    return await this.destroy(`/api/surveys/${surveyID}/data`);
  }

  async addAdmin(surveyID, email) {
    return await this.create(`/api/surveys/${surveyID}/admins`, { email });
  }

  async dropAdmin(surveyID, email) {
    return await this.destroy(`/api/surveys/${surveyID}/admins`, { email });
  }

  async updateCategories(categories) {
    console.log('TODO: set categories', categories);
  }

  async renameCategory(oldCategory, newCategory) {
    console.log('TODO: rename category', oldCategory, newCategory);
  }
}

function getTimestamp() {
  return new Date().toISOString()
}

const instance = window["db"] = new DB();

export default instance;
