import React from 'react';
import { Button, Input } from 'antd';
import { AppState } from '../types';
import SurveyAdminsForm from './SurveyAdminsForm';

type Props = {
  app: AppState;
}

class SurveyAdminView extends React.Component<Props> {
  render() {
    let { app } = this.props;
    
    return (
      <div style={{padding: 20}}>
        <SurveyAdminsForm app={app} />

        <h3>Danger</h3>
        <Button onClick={this.handleReset}>Reset master values</Button>
      </div>
    )
  }

  handleReset = () => {
    if (window.confirm("Are you sure want to reset the master values? This cannot be undone.")) {
      let { app } = this.props;
      app.actions.resetMasterValues();
    }
  }
}

export default SurveyAdminView;
