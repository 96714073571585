import React from 'react';
// import logo from './logo.svg';
// import ConnectionEditor from './PixiConnectionEditor';
import { RouteProps } from 'react-router';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import { Actions, Helpers, Survey, Session } from '../types';
import LoginScreen from './LoginScreen';
import SurveyIndex from './SurveyIndex';
import SurveyAdminApp from './SurveyAdminApp';
import db from '../db';

class AdminApp extends React.Component<RouteProps> {
  state: {
    session: Session;
    surveys: Survey[];
  }

  actions: Actions;
  helpers: Helpers;

  constructor(props) {
    super(props);

    this.state = {
      session: null,
      surveys: [],
    }

    // helpers
    let helpers: Helpers = this.helpers = {};
    helpers.getSurvey = (id) => this.state.surveys.find(survey => survey.id === id);
    helpers.getSurveyIndex = (id) => this.state.surveys.findIndex(survey => survey.id === id);

    // actions
    let actions: Actions = this.actions = {};

    // TODO: how do we handle expired sessions?
    actions.login = async (session: Session) => {
      if (session) {
        // persist the session to localstorage
        localStorage.setItem('session', JSON.stringify(session));

        // allow db to make authenticated requests
        db.token = session.token;

        // save session data locally so we can use it within the ui
        this.setState({ session });

        // kick off the data loading
        this.actions.fetchSurveys();
      } else {
        // attempt to resume previous session
        try {
          let session = JSON.parse(localStorage.getItem('session'));

          if (session) {
            this.actions.login(session);
          }
        } catch (error) {}
      }
    }

    actions.logout = () => {
      localStorage.removeItem('session');
      db.token = null;
      this.setState({session: null});
      // TODO: redirect?
    }

    actions.fetchSurveys = async () => {
      let surveys = await db.getSurveys();
      this.setState({ surveys });
    }

    actions.createSurvey = async (attrs) => {
      let survey = await db.createSurvey(attrs);
      this.setState({surveys: [survey, ...this.state.surveys]});
      return survey;
    }

    actions.updateSurvey = async (id, attrs) => {
      let survey = await db.updateSurvey(id, attrs);

      let { surveys } = this.state;
      let index = helpers.getSurveyIndex(survey.id)

      this.setState({
        surveys: [
          ...surveys.slice(0, index),
          survey,
          ...surveys.slice(index + 1)
        ]
      });

      return survey;
    }

    actions.deleteSurvey = async (id) => {
      await db.deleteSurvey(id);

      let { surveys } = this.state;
      let index = helpers.getSurveyIndex(id)

      this.setState({
        surveys: [
          ...surveys.slice(0, index),
          ...surveys.slice(index + 1)
        ]
      });
    }
  }

  componentWillMount() {
    this.actions.login();
  }

  render() {
    if (!this.state.session) {
      return <LoginScreen onLogin={this.actions.login} />;
    } else {
      return (
        <div className="admin-app">
          <Switch>
            <Route path="/logout" render={this.renderLogoutRoute} />
            <Route path="/admin/surveys/:id" children={this.renderSurveyView} />
            <Route children={this.renderSurveyIndex} />
          </Switch>
        </div>
      )
    }
  }

  renderSurveyIndex = () => {
    let { actions, helpers } = this;
    let { session, surveys } = this.state;
    return <SurveyIndex actions={actions} helpers={helpers} session={session} surveys={surveys} />;
  }

  renderSurveyView = ({ match }) => {
    let { actions, helpers } = this;
    let { surveys } = this.state;
    let survey;

    if (match) {
      survey = helpers.getSurvey(Number(match.params.id));

      if (survey) {
        return <SurveyAdminApp survey={survey} />;
      }
    }

    return null;
  }

  renderLogoutRoute = () => {
    this.actions.logout();
    this.props.history.replace('/admin');
    return <p>You have been logged out.</p>
  }
}

export default withRouter(AdminApp);
