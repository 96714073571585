import React from 'react';

// margin:auto container used to prevent top of content from being cutoff
// when content is taller than viewport
const Centered = ({ children }) => {
  let style = {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
  
  return (
    <div style={style}>
      <div style={{margin: 'auto'}}>
        {children}
      </div>
    </div>
  )
}

export default Centered;
