import debounce from "lodash/debounce";
import React from "react";
import { Row, Col, Tag, Icon, Input, Statistic, Button } from "antd";
import { Sortable, Draggable } from "@shopify/draggable";
import MasterValueListItem from "./MasterValueListItem";
import RawValueList from "./RawValueList";
import db from "../db";
import { AppState } from "../types";
import { uuid, getUniqueValuesFromResponse, getSurveyStats } from "../util";
import { playDragSound, playDropSound } from "./helpers/sounds";
import "./MappingsEditor.css";

function getNextMasterValueCode(state) {
  return state.masterValues.length + 1;
}

type MappingsEditorProps = {
  app: AppState;
};

type MappingsEditorState = {
  mappedValues: string[];
  unmappedFilter: string;
  unmappedValues: string[];
  ignoredValues: string[];
  stats?: {
    averageNodeReduction: number;
    averageEdgeReduction: number;
    averageDensityIncrease: number;
  };
};

class MappingsEditor extends React.Component<
  MappingsEditorProps,
  MappingsEditorState
> {
  unmappedFilterRef: any;
  draggable: any;

  constructor(props) {
    super(props);

    this.unmappedFilterRef = React.createRef();
    this.handleUnmappedFilterChange = debounce(
      this.handleUnmappedFilterChange,
      500
    );

    this.state = {
      mappedValues: [],
      unmappedFilter: "",
      unmappedValues: [],
      ignoredValues: [],
    };
  }

  componentWillMount() {
    this.refresh(this.props);
  }

  componentDidMount() {
    this.initializeDraggables();
  }

  componentDidUpdate() {
    this.initializeDraggables();
  }

  componentWillReceiveProps(nextProps) {
    this.refresh(nextProps);
  }

  render() {
    let { app } = this.props;
    let { survey, nextMasterValueID } = app.state;
    let {
      mappedValues,
      unmappedFilter,
      unmappedValues,
      ignoredValues,
      stats,
    } = this.state;
    let nextCode = mappedValues.length + 2;
    let focusMaster = { id: "focus", value: survey.focus };
    let nextMaster = { id: nextMasterValueID, value: "" };
    let ignoreMaster = { id: "ignore", value: "Ignored values" };

    return (
      <div className="mappings-editor">
        <div className="mappings-editor-main">
          <div className="mappings-editor-stats">
            <Statistic
              title="Avg reduction # nodes"
              value={stats.averageNodeReduction}
              precision={0}
            />
            <Statistic
              title="Avg reduction # edges"
              value={stats.averageEdgeReduction}
              precision={0}
            />
            <Statistic
              title="Avg increase in density"
              value={stats.averageDensityIncrease * 100}
              precision={0}
              suffix="%"
            />
          </div>

          <ul className="mappings-list">
            <MasterValueListItem
              key="focus"
              app={app}
              code={1}
              master={focusMaster}
              aliases={[survey.focus]}
              readonly
            />
            {mappedValues.map(mappedValue =>
              this.renderMappedValue(mappedValue)
            )}
            <MasterValueListItem
              key={nextCode}
              app={app}
              code={nextCode}
              master={nextMaster}
              isNew
            />
            <MasterValueListItem
              key="ignore"
              app={app}
              code={<Icon type="stop" />}
              master={ignoreMaster}
              aliases={ignoredValues}
              readonly
            />
          </ul>
        </div>

        <div className="mappings-editor-footer">
          <div className="unmapped-values" style={{ outline: "none" }}>
            {/*<input ref={this.unmappedFilterRef} type='text' className='filter-input ant-input' placeholder='Filter' onChange={this.handleUnmappedFilterChange} />*/}
            <RawValueList values={unmappedValues} actions={app.actions} />
          </div>
        </div>
      </div>
    );
  }

  renderMappedValue(mappedValue) {
    let { app } = this.props;
    let { master } = mappedValue;

    return <MasterValueListItem key={master.id} app={app} {...mappedValue} />;
  }

  handleUnmappedFilterChange = event => {
    let unmappedFilter = this.unmappedFilterRef.current.value;
    this.setState({ unmappedFilter });
  };

  refresh(props) {
    let { app } = props || this.props;
    let { survey, surveyData } = app.state;
    let { masterValues, aliasTable, responses } = surveyData;
    let unmappedValues = new Set<string>();
    let aliasesByID = {};

    // aliasesByID[null] = new Set();
    aliasesByID["ignore"] = new Set();
    masterValues.forEach(master => (aliasesByID[master.id] = new Set()));

    responses.forEach(response => {
      if (response.ignored) return;

      let values = getUniqueValuesFromResponse(response);
      values.forEach(value => {
        let id = aliasTable[value];

        if (id) {
          // already mapped
        } else {
          unmappedValues.add(value);
        }
      });
    });

    Object.keys(aliasTable).forEach(rawValue => {
      let id = aliasTable[rawValue];
      let set = aliasesByID[id];

      if (set) {
        set.add(rawValue);
      } else {
        unmappedValues.add(rawValue); // master value no longer exists
      }
    });

    let mappedValues = masterValues.map((master, index) => {
      let code = index + 2;
      let aliases = Array.from(aliasesByID[master.id]);
      return { code, master, aliases };
    });

    this.setState({
      mappedValues,
      unmappedValues: Array.from(unmappedValues),
      ignoredValues: Array.from(aliasesByID["ignore"]),
      stats: getSurveyStats(survey, surveyData),
    });
  }

  initializeDraggables() {
    let { app } = this.props;

    if (this.draggable) this.draggable.destroy();

    const containers = document.querySelectorAll(".drop-target");
    const draggable = (this.draggable = new Sortable(containers, {
      delay: 0,
      draggable: ".drag-target",
      droppable: ".drop-target",
      scrollable: {
        speed: 6,
        sensitivity: 12,
      },
    }));

    // Events
    // https://github.com/Shopify/draggable/tree/master/src/Draggable#events
    draggable.on("drag:start", () => {
      playDragSound();
    });

    draggable.on("drag:stop", event => {
      let { source, sourceContainer, currentOverContainer } = draggable;
      let value = source.getAttribute("data-value");
      let id = currentOverContainer.getAttribute("data-id");

      app.actions.move(value, id);

      playDropSound();
    });

    // draggable.on('drag:over:container', () => {
    //   Sounds.down.play();
    // })
  }
}

export default MappingsEditor;
