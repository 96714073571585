import chroma from "chroma-js";
import React from "react";
import { Radio, Tooltip } from "antd";
import { Matrix } from "../types";
import { getMatrixDomain } from "../util";
import "./AdjacencyMatrix.css";

type AdjacencyMatrixProps = {
  matrix: Matrix;
  isMaster?: boolean;
};

class AdjacencyMatrix extends React.Component<AdjacencyMatrixProps> {
  render() {
    let { matrix, isMaster } = this.props;
    let { labels, data } = matrix;

    let domain = getMatrixDomain(matrix);
    let scale = chroma.scale("Viridis").domain(domain);

    let headerLabels = labels.map(label => {
      let truncatedLabel =
        label.length > 14 ? `${label.substring(0, 14)}...` : label;

      return (
        <Tooltip title={label} /*position="bottom"*/>
          <td>{truncatedLabel}</td>
        </Tooltip>
      );
    });

    let rows = data.map((row, i) => {
      let fromLabel = labels[i];

      let cells = row.map((value, j) => {
        let toLabel = labels[j];
        let checked = value > 0;
        let title = checked
          ? `${fromLabel} --> ${toLabel}`
          : `No connection from "${fromLabel}" to "${toLabel}"`;
        let style;

        if (isMaster) {
          let backgroundColor = scale(value);
          let color = backgroundColor.luminance() >= 0.5 ? "#000" : "#fff";
          let opacity = 0.9;

          style = { color, backgroundColor, opacity };
        }

        return (
          <Tooltip title={title} placement="top">
            <td style={style}>
              {isMaster ? value || "" : <Radio checked={checked} />}
            </td>
          </Tooltip>
        );
      });

      return (
        <tr>
          <Tooltip title={fromLabel} placement="right">
            <td>{fromLabel}</td>
          </Tooltip>
          {cells}
        </tr>
      );
    });

    return (
      <table className="adjacency-matrix">
        <thead>
          <tr>
            <td></td>
            {headerLabels}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }
}

export default AdjacencyMatrix;
