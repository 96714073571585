export const foodInsecurity = {
  values: [
    {
      id: 'vx1',
      value: "I struggle to pay all of my bills",
      children: [
        {id: 'vx10', value: "Sydney is expensive place to live", children: []},
        {id: 'vx11', value: "Most of my money gets spent on rent", children: []},
        {id: 'vx12', value: "", children: []}
      ]
    },
    {
      id: 'vx2',
      value: "Good food is expensive",
      children: [
        {id: 'vx20', value: "Sydney is expensive place to live", children: []},
        {id: 'vx21', value: "Hard to find a good job", children: []},
        {id: 'vx22', value: "", children: []}
      ]
    },
    {
      id: 'vx3',
      value: "Lack of time",
      children: [
        {id: 'vx30', value: "Do not own a car", children: []},
        {id: 'vx31', value: "", children: []}
      ]
    },
    {
      id: 'vx4',
      value: "Hard to get to the shop",
      children: [
        {id: 'vx40', value: "Do not own a car", children: []},
        {id: 'vx41', value: "Live far from public transport", children: []},
        {id: 'vx42', value: ""}
      ]
    },
    {
      id: 'vx5',
      value: "My house doesn't have enough food storage space",
      children: [
        {id: 'vx50', value: "I have a large family", children: []},
        {id: 'vx51', value: "", children: []}
      ]
    },
    {
      id: 'vx6',
      value: "",
      children: [
        {id: 'vx60', value: "", children: []}
      ]
    }
  ],
  otherEdges: []
}

export const testResponse = {
  values: [
    {
      id: 'vx1',
      value: 'One',
      children: [
        {id: 'vx10', value: 'A', children: []},
        {id: 'vx11', value: 'B', children: []}
      ]
    },
    {
      id: 'vx2',
      value: 'Two',
      children: [
        {id: 'vx12', value: 'C', children: []},
        {id: 'vx13', value: 'D', children: []}
      ]
    },
    {
      id: 'vx3',
      value: 'Three',
      children: [
        {id: 'vx14', value: 'D', children: []},
        {id: 'vx15', value: 'E', children: []}
      ]
    },
  ],
  otherEdges: [
    {from: 'vx15', to: 'vx10'}
  ]
}

export const list = [
  foodInsecurity,
  testResponse
]
