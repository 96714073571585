import React from 'react';
import { Radio } from 'antd';

class RadioInput extends React.Component {
  render() {
    let { values } = this.props;

    let inputs = values.map(({ label, value }) => {
      return <Radio key={value} value={value}>{label}</Radio>;
    })

    return <Radio.Group onChange={this.handleChange}>{inputs}</Radio.Group>;
  }

  handleChange = (event) => {
    let { value } = event.target;
    this.props.onChange(value);
  }
}

export default RadioInput;
