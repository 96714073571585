import './AnalyticsView.css';

import $ from 'jquery';
import React from 'react';
import { AppState, Response } from '../types';
import FilterInput from './FilterInput';
import createScatterPlot from './dataviz/createScatterPlot';
import { filterResponses } from '../util';

type Props = {
  app: AppState;
}

type State = {
  responses: Response[];
}

class AnalyticsView extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {responses: []};
  }

  componentWillMount() {
    this.updateFilter(null);
  }

  componentDidMount() {
    this.refreshDataviz();
  }

  componentDidUpdate() {
    this.refreshDataviz();
  }

  render() {
    let { responses } = this.state;

    return (
      <div className='analytics-view'>
        <header>
          <FilterInput onChange={this.handleFilterChange} />
        </header>

        {responses.length === 0 ? this.renderEmpty() : this.renderDataviz()}
      </div>
    )
  }

  renderEmpty() {
    return <p>No responses found</p>;
  }

  renderDataviz() {
    return (
      <div>
        <div id='scatter' style={{height: 'calc(100vh - 80px)'}}></div>
      </div>
    )
  }

  refreshDataviz() {
    let { app } = this.props;
    let { responses } = this.state;

    if (responses.length === 0) return;

    createScatterPlot(app, responses, '#scatter');
  }

  updateFilter(filter) {
    let { app } = this.props;
    let { surveyData } = app.state;
    let responses = filterResponses(surveyData.responses, filter);

    this.setState({ responses });
  }

  handleFilterChange = (filter) => {
    this.updateFilter(filter);
  }
}

export default AnalyticsView;
