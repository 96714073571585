import React from 'react';
import { Input, Button } from 'antd';

const InputGroup = Input.Group;

class NewsletterForm extends React.Component {
  state = {submitted: false}

  render() {
    let { submitted } = this.state;

    if (submitted) {
      return (
        <div>
          <p>Thanks! We'll be in touch.</p>
        </div>
      )
    } else {
      return (
        <div>
          <p>
            If you would like to be kept in the loop, please enter your email below:
          </p>

          <form onSubmit={this.handleSubmit}>
            <InputGroup compact>
              <Input ref='input' placeholder='you@example.com' style={{width: 'auto'}} onPressEnter={this.handleSubmit} />
              <Button type='primary' onClick={this.handleSubmit}>Keep me updated</Button>
            </InputGroup>
          </form>
        </div>
      )
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    let email = this.refs.input.input.value.trim();

    if (email) {
      this.setState({submitted: true});
      this.props.onSubmit(email);
    }
  }
}

export default NewsletterForm;
