import $ from 'jquery';
import _ from 'lodash';
import React, { Component } from 'react';
// import logo from './logo.svg';
import { Drawer, Tooltip, Button, Input, List, Icon, Spin, Empty } from 'antd';
import TextInput from './TextInput';
import Centered from './Centered';
import SpinnerScreen from './SpinnerScreen';
import ConnectionEditor from './RoughJSConnectionEditor';
import NewsletterForm from './NewsletterForm';
import renderInput from './inputs/renderInput';
import { prepareResponse, getGraph, getMatrix, getUniqueValues, createCleanResponse } from '../util';
import db from '../db';
import * as examples from './data/examples';
import './SurveyApp.css';
import './SurveyAppTheme.css';

let { saveSvgAsPng } = require('save-svg-as-png');

class SurveyApp extends Component {
  constructor() {
    super();

    this.state = {
      ready: false,
      error: false,
      currentScreenIndex: -1,
      currentTagList: [],
      suggestions: [],
      survey: {
        focus: "",
        direction: "upstream",
        intro: "",
        intake: [],
        prompt1: "",
        prompt2: "",
        promptStory: "",
        thanks: ""
      },
      response: {
        email: '',
        story: '',
        tags: '',
        data: {
          values: [],
          otherEdges: []
        }
      },
      submissionStatus: null,
      submissionError: null
    }

    prepareResponse(this.state);

    // actions
    let actions = this.actions = {};

    actions.load = async (surveyID) => {
      let survey = await db.getSurvey(surveyID);

      if (survey) {
        this.screens = [];
        this.screens.push(() => this.renderIntroScreen());

        // TODO: move this into a helper
        // intake screen (only condition screen)
        let intake;

        if (survey.intake) {
          try {
            intake = JSON.parse(survey.intake);

            if (!Array.isArray(intake)) {
              console.log("Intake questionnaire must be an array");
              intake = null;
            }
          } catch (error) {
            console.log("Error parsing intake questionnaire");
          }
        }

        if (surveyID == 47 || surveyID == 48) {
          survey.showStoryScreen = false;
          survey.showNewsletterForm = false;
        } else {
          survey.showStoryScreen = true;
          survey.showNewsletterForm = true;
        }

        // https://github.com/kumu/kumu/issues/9176
        if (!survey.promptStory) {
          survey.showStoryScreen = false;
        }

        // https://github.com/kumu/kumu/issues/9176
        if (!survey.newsletter) {
          survey.showNewsletterForm = false;
        }

        // example intake form for testing
        // intake = require("./data/intake/ndis.json");

        if (intake && intake.length > 0) {
          this.screens.push(() => this.renderIntakeScreen(intake));
        }

        this.screens.push(() => this.renderPrompt1Screen());
        this.screens.push(() => this.renderPrompt2Screen());
        this.screens.push(() => this.renderConnectionEditorScreen());

        if (survey.showStoryScreen) {
          this.screens.push(() => this.renderStoryScreen());
        }

        this.screens.push(() => this.renderReviewScreen());

        // TODO: if loading response need to call this.actions.refresh() after
        this.setState({ready: true, currentScreenIndex: 0, survey, surveyID});
      } else {
        console.error('unable to load survey', surveyID);
        this.setState({error: true});
      }
    }

    actions.loadExampleResponse = (index) => {
      let { response } = this.state;
      response = {...response, data: examples.list[index]};
      this.setState({ response }, actions.refresh);
    }

    actions.focusNextInput = () => {
      let emptyInputs = $(".text-input input[type=text]").filter((i, el) => !el.value);

      if (emptyInputs.length > 0) {
        emptyInputs[0].focus();
      }
    }

    actions.back = () => {
      let { currentScreenIndex } = this.state;
      currentScreenIndex = Math.max(currentScreenIndex - 1, 0);
      this.setState({ currentScreenIndex });
      document.body.focus();  // clear button focus
    }

    actions.next = () => {
      let { currentScreenIndex } = this.state;
      currentScreenIndex = Math.min(currentScreenIndex + 1, this.screens.length - 1);

      this.setState({ currentScreenIndex }, actions.focusNextInput);

      document.body.focus(); // clear button focus
    }

    actions.toggleTags = (index, tags) => {
      let { currentTagList } = this.state;

      currentTagList = [...currentTagList];
      currentTagList[index] = tags;

      this.setState({ currentTagList });
    }

    actions.saveTagsToResponse = (callback) => {
      let { currentTagList, response } = this.state;
      let tags = _(currentTagList).flatten().compact().join(" ");

      response = { ...response, tags };

      this.setState({ response }, callback);
    }

    actions.edit = (path, value) => {
      let [ i, j ] = path;
      let { response } = this.state;

      if (path.length === 1) {
        response.data.values[i].value = value;
      } else {
        response.data.values[i].children[j].value = value;
      }

      prepareResponse(this.state);

      this.setState({ response });
    }

    actions.delete = (path) => {
      let [ i, j ] = path;
      let { response } = this.state;

      if (path.length === 1) {
        response.data.values = [
          ...response.data.values.slice(0, i),
          ...response.data.values.slice(i + 1)
        ]
      } else {
        response.data.values[i] = {
          ...response.data.values[i],
          children: [
            ...response.data.values[i].children.slice(0, j),
            ...response.data.values[i].children.slice(j + 1)
          ]
        }
      }

      prepareResponse(this.state);

      this.setState({ response });
    }

    actions.connect = (from, to) => {
      // TODO: this should be smart about how the connections are added
      // - first order to focus: modify response
      // - first order to second order: modify response
      // - all others: add as other edge
      let { response } = this.state;
      let edge = { from, to };

      response.data.otherEdges = [...response.data.otherEdges, edge];

      this.setState({ response });
    }

    actions.disconnect = (from, to) => {
      let { response } = this.state;
      response.data.otherEdges.pop();
      this.setState({ response });
    }

    actions.updateStory = (story) => {
      let { response } = this.state;
      response.story = story;
      this.setState({ response });
    }

    actions.updateEmail = (email) => {
      let { responseID } = this.state;
      db.updateResponse(responseID, { email });
    }

    actions.refresh = () => {
      let suggestions = getUniqueValues(this.state);
      this.setState({ suggestions });
    }

    actions.downloadScreenshot = () => {
      this.downloadScreenshot();
    }

    actions.submit = () => {
      let { surveyID } = this.state;
      let response = createCleanResponse(this.state.response);

      response.source = 'survey';

      console.log("submitting response");
      console.log("response =", JSON.stringify(response, null, '  '));

      this.setState({submissionStatus: 'pending'});

      db.createResponse(surveyID, response)
        .then(response => {
          console.log("response submitted successfully", response);
          this.setState({submissionStatus: 'success', responseID: response.id});
        })
        .catch(error => {
          console.error("response submission failed", error);
          this.setState({submissionStatus: 'error'});
        })
    }

    actions.abort = () => this.setState({submissionStatus: null});
    actions.resubmit = () => actions.submit();
  }

  componentWillMount() {
    let { surveyID } = this.props;
    this.actions.load(surveyID);

    // temporary workaround for style clash between survey app and admin app
    document.body.className += ' body-survey-app';

    document.addEventListener("keydown", (event) => {
      let num = Number(event.key);

      if (Number.isInteger(num) && event.ctrlKey && event.target.tagName !== "INPUT") {
        this.actions.loadExampleResponse(num - 1);
      }
    })
  }

  // componentWillUpdate() {
  //   let editor = document.getElementById('svg-connection-editor');
  //
  //   if (editor) {
  //     this.actions.downloadScreenshot();
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentScreenIndex !== prevState.currentScreenIndex) {
      $('button:focus').blur();
      this.actions.focusNextInput();

      // prevent initial input focus from pushing heading off the top for ios
      document.body.scrollTop = 0;
    }
  }

  render() {
    let { ready, error, currentScreenIndex, submissionStatus } = this.state;

    if (ready) {
      switch (submissionStatus) {
        case 'success': return this.renderSubmitSuccessScreen();
        case 'error':   return this.renderSubmitErrorScreen();
        case 'pending': return this.renderSubmitPendingScreen();
        default: return this.renderScreen(currentScreenIndex);
      }
    } else if (error) {
      return <Centered><Empty description='Survey not found' /></Centered>;
    } else {
      return <SpinnerScreen />;
    }
  }

  // TODO: reset screen-body scroll after update
  renderScreen(index) {
    return this.screens[index]();
  }

  renderIntroScreen() {
    let { actions } = this;
    let { survey, surveyID } = this.state;

    let defaultIntro = `
      System Effects is a tool that allows
      us to aggregate and analyze user-generated system maps of a problem
      and its associated factors.

      Today we'll be using System Effects to explore ${survey.focus.toLowerCase()}.
    `

    let intro = survey.intro || defaultIntro;
    let introParagraphs = intro.split(/\n\n+/).map(p => <p>{p}</p>);

    let logo = {
      39: '/images/clarence-valley-council.jpg',
    }[surveyID];

    // uncomment to test logo
    // logo = '/images/clarence-valley-council.jpg';

    return (
      <div className="app">
        <Centered>
          <div className="screen">
            <div className="screen-body">
              <div className="container">
                {logo && <img className="screen-media" src={logo} />}

                <h1 style={{lineHeight: "1em", textAlign: "center"}}>
                  <aside style={{fontSize: "14px", whiteSpace: "nowrap"}}>
                    Welcome to the System Effects survey on
                  </aside>

                  {survey.name}
                </h1>

                <div>
                  {introParagraphs}
                </div>
              </div>
            </div>
            <div className="screen-nav">
              <Button.Group>
                <Button key='next' size='large' type='primary' onClick={actions.next}>
                  Let's begin
                </Button>
              </Button.Group>
            </div>
          </div>
        </Centered>
      </div>
    );
  }

  renderIntakeScreen(intake) {
    let { actions } = this;

    const handleNext = () => {
      actions.saveTagsToResponse(() => {
        actions.next();
      });
    }

    let prompts = intake.map(({ type, prompt, placeholder, prefix, values }, index) => {
      let onChange = (values) => {
        if (!Array.isArray(values)) values = [values];

        // TODO: should we remove whitespace? upcase?
        let tags = values
          .filter(value => value)
          .map(value => `${prefix}:${value}`);

        actions.toggleTags(index, tags);
      }

      let input = renderInput(type, { prefix, values, placeholder, onChange });

      return (
        <div className="form-group">
          <h3>{prompt}</h3>
          {input}
        </div>
      )
    })

    return (
      <div className="app">
        <div className="screen">
          <div className="screen-body">
            <div className="container">
              <h2 style={{textAlign: "center", marginTop: "3em", marginBottom: "3em"}}>
                Before we get started, please answer the following:
              </h2>

              <form className="intake-form">
                {prompts}
              </form>
            </div>
          </div>
          <div className="screen-nav">
            <Button.Group>
              {/*
              <Button key='back' size='large' onClick={actions.back} tabIndex='99999'>
                <Icon type="left" /> Back
              </Button>
              */}

              <Button key='next' size='large' onClick={handleNext}>
                Next <Icon type="right" />
              </Button>
            </Button.Group>
          </div>
        </div>
      </div>
    );
  }

  renderPrompt1Screen() {
    let { actions } = this;
    let { survey, response } = this.state;
    let { values } = response.data;

    let inputs = values.map((entry, i) => {
      let { value, children } = entry;
      let placeholder = "";

      const edit = (value) => {
        actions.edit([i], value);
      }

      const destroy = () => {
        actions.delete([i]);
      }

      return (
        <li>
          <div className="input-list-group">
            <TextInput value={value} placeholder={placeholder} onChange={edit} />
            <button type='button' className='delete-btn' onClick={destroy}>&times;</button>
          </div>
        </li>
      )
    })

    return (
      <div className="app">
        <div className="screen">
          <h3 className="screen-heading">{survey.prompt1}</h3>
          <div className="screen-body scroll">
            <div className="container">
              <ul className="input-list">
                {inputs}
              </ul>
            </div>
          </div>
          <div className="screen-nav">
            <Button.Group>
              {/*
              <Button key='back' size='large' onClick={actions.back} tabIndex='99999'>
                <Icon type="left" /> Back
              </Button>
              */}

              <Button key='next' size='large' onClick={actions.next}>
                Next <Icon type="right" />
              </Button>
            </Button.Group>
          </div>
        </div>
      </div>
    );
  }

  renderPrompt2Screen() {
    let { actions } = this;
    let { survey } = this.state;

    return (
      <div className="app">
        <div className="screen">
          <h3 className="screen-heading">
            {survey.prompt2}
            <aside className="help-text text-themed">(it's ok to repeat responses)</aside>
          </h3>

          <div className="screen-body scroll">
            <div className="container">
              {this.renderBarrierCausesForm()}
            </div>
          </div>

          <div className="screen-footer">
            <div className="screen-nav">
              <Button.Group>
                <Button key='back' size='large' onClick={actions.back} tabIndex='99999'>
                  <Icon type="left" /> Back
                </Button>

                <Button key='next' size='large' onClick={actions.next}>
                  Next <Icon type="right" />
                </Button>
              </Button.Group>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderBarrierCausesForm() {
    let { actions } = this;
    let { survey, suggestions, response } = this.state;
    let { values } = response.data;

    // strip last blank input since we're not letting people edit the root value now
    // inputs = inputs.slice(0, inputs.length - 1);
    values = values.filter(entry => entry.value);

    let inputs = values.map((entry, i) => {
      let { value, children } = entry;
      let parentValue = value;
      let label;

      const edit = (value) => {
        actions.edit([i], value);
      }

      if (survey.direction === 'upstream') {
        // I have no time because... (upstream causes)
        label = `${parentValue} because...`;
      } else {
        // Harder to find language that works for all values here
        // - Because I have no time... (downstream effects)
        // - Because do not own car...
        // - Because stress...
        label = parentValue;
      }

      let items = children.map((entry, j) => {
        let { value } = entry;
        let placeholder = "";

        if (parentValue) {
          if (survey.direction === 'upstream') {
            // I have no time because... (upstream causes)
            // placeholder = `${parentValue} because...`;
            placeholder = '';
          } else {
            // Harder to find language that works for all values here
            // - Because I have no time... (downstream effects)
            // - Because do not own car...
            // - Because stress...
            placeholder = ``;
          }
        }

        const edit = (value) => {
          actions.edit([i, j], value);
        }

        const destroy = () => {
          actions.delete([i, j]);
        }

        const refresh = () => {
          actions.refresh();
        }

        return (
          <li>
            <div className="input-list-group">
              <TextInput value={value} placeholder={placeholder} autocomplete={suggestions} onChange={edit} onBlur={refresh} />
              <button type='button' className='delete-btn' onClick={destroy}>&times;</button>
            </div>
          </li>
        )
      })

      return (
        <li>
          <div className="input-list-group unstyled">
            {label}
            {/*<TextInput value={value} onChange={edit} />*/}
          </div>
          <ul className="input-list">
            {items}
          </ul>
        </li>
      )
    })

    return (
      <ul className="input-list">
        {inputs}
      </ul>
    );
  }

  renderConnectionEditorScreen() {
    let { actions } = this;
    let graph = getGraph(this.state);

    const handleEdgeCreated = (edge) => {
      actions.connect(edge.from.id, edge.to.id);
    }

    const handleEdgeCreatedUndo = (edge) => {
      actions.disconnect(edge.from.id, edge.to.id);
    }

    const handleUndo = () => {
      this.refs.editor.actions.undo();
    }

    return (
      <div className="app">
        <div className="screen connection-editor-screen">
          <h3 className="screen-heading">
            Please review the connections you identified in the previous steps.
            Any missing connections you would like to add?
            <aside className="help-text text-themed">(click and drag to draw additional connections)</aside>
          </h3>

          <div className="screen-body">
            <ConnectionEditor ref='editor' graph={graph} onEdgeCreated={handleEdgeCreated} onEdgeCreatedUndo={handleEdgeCreatedUndo} />
          </div>

          <div className="screen-footer">
            <div className="screen-nav">
              <Button.Group>
                <Button size='large' onClick={actions.back} tabIndex='99998'>
                  <Icon type="left" /> Back
                </Button>

                <Tooltip title='Download screenshot'>
                  <Button size='large' onClick={actions.downloadScreenshot} tabIndex='99999'>
                    <Icon type="download" /> Download
                  </Button>
                </Tooltip>

                <Tooltip title='Undo edit'>
                  <Button size='large' onClick={handleUndo}>
                    <Icon type="undo" /> Undo
                  </Button>
                </Tooltip>

                <Button size='large' onClick={actions.next}>
                  Next <Icon type="right" />
                </Button>
              </Button.Group>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStoryScreen() {
    let { actions } = this;
    let { survey, response } = this.state;

    let defaultPrompt = `Would you mind sharing a personal story about how ${survey.focus.toLowerCase()} has affected you?`
    let prompt = survey.promptStory || defaultPrompt;

    const handleChange = (event) => actions.updateStory(event.target.value);

    return (
      <div className="app">
        <Centered>
          <div className="screen">
            <h3 className="screen-heading">
              <div className="container">
                {prompt}
              </div>
            </h3>

            <div className="screen-body scroll">
              <div className="container">
                <Input.TextArea value={response.story} onChange={handleChange} autosize={{ minRows: 10 }} placeholder="Share your story... (optional)" />
              </div>
            </div>

            <div className="screen-footer">
              <div className="screen-nav">
                <Button.Group>
                  <Button size='large' onClick={actions.back} tabIndex='99999'>
                    <Icon type="left" /> Back
                  </Button>

                  <Button size='large' onClick={actions.next}>
                    Next <Icon type="right" />
                  </Button>
                </Button.Group>
              </div>
            </div>
          </div>
        </Centered>
      </div>
    );
  }

  renderReviewScreen() {
    let { actions } = this;
    let { survey } = this.state;

    return (
      <div className="app">
        <Centered>
          <div className="screen">
            <h3 className="screen-heading">
              Well done!
            </h3>

            <div className="screen-body">
              <div className="container">
                <p>
                  If you would like to review or edit your response you are
                  welcome to do so now.
                </p>

                <p>
                  When you're ready to submit your response please click the
                  submit button below.
                </p>
              </div>
            </div>

            <div className="screen-footer">
              <div className="screen-nav">
                <Button.Group>
                  <Button size='large' onClick={actions.back} tabIndex='99999'>
                    <Icon type="left" /> Back
                  </Button>

                  <Button size='large' type='primary' onClick={actions.submit}>
                    Submit response
                  </Button>
                </Button.Group>
              </div>
            </div>
          </div>
        </Centered>
      </div>
    );
  }

  renderSubmitPendingScreen() {
    return <SpinnerScreen />;
  }

  renderSubmitSuccessScreen() {
    let { actions } = this;
    let { survey } = this.state;

    const handleSubmit = email => actions.updateEmail(email);

    return (
      <div className="app">
        <Centered>
          <div className="screen">
            <div className="screen-body">
              <div className="container text-center">
                <div style={{marginBottom: "1em"}}>
                  <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" style={{fontSize: "4em"}} />
                </div>
                <h3>Response saved</h3>
                <p dangerouslySetInnerHTML={{ __html: survey.thanks || "Thank you for taking the time to complete this survey!" }} />
                {survey.showNewsletterForm && <NewsletterForm onSubmit={handleSubmit} />}
              </div>
            </div>
          </div>
        </Centered>
      </div>
    );
  }

  renderSubmitErrorScreen() {
    let { actions } = this;

    return (
      <div className="app">
        <Centered>
          <div className="screen">
            <div className="screen-body flex-center scroll contained">
              <p>Sorry, we weren't able to save your response</p>
            </div>

            <div className="screen-nav contained">
              <button type='button' className='btn' onClick={actions.abort} tabIndex='99999'>
                Back
              </button>

              <button type='button' className='btn btn-primary' onClick={actions.resubmit}>
                Try again
              </button>
            </div>
          </div>
        </Centered>
      </div>
    );
  }

  // https://www.npmjs.com/package/save-svg-as-png
  downloadScreenshot() {
    let svg = document.getElementById('svg-connection-editor');

    if (!svg) return;

    let svgOffset = $(svg).offset();
    let scene = svg.childNodes[1];
    let bounds = scene.getBoundingClientRect();

    let padding = 20;

    // pass canvg for IE support?
    let options = {
      encoderType: 'image/png',
      encoderOptions: 1,
      scale: 3,
      backgroundColor: '#ffffff',
      top: bounds.top - svgOffset.top - padding,
      left: bounds.left - svgOffset.left - padding,
      width: bounds.width + 2 * padding,
      height: bounds.height + 2 * padding,
    }

    saveSvgAsPng(svg, "system-effects-screenshot.png", options);
  }
}

export default SurveyApp;
