import React from 'react';
import { Tag } from 'antd';
import { MasterValue, Actions } from '../types';
import RawValue from './RawValue';

import './RawValueList.css';

type Props = {
  master?: MasterValue;
  values: string[];
  actions: Actions;
}

class RawValueList extends React.Component<Props> {
  // Once rendered all internal DOM edits handled by draggable
  shouldComponentUpdate() {
    return false;
  }

  render() {
    let { master, values } = this.props;
    let masterID = master && master.id;

    return (
      <div className='raw-value-list drop-target' data-id={masterID}>
        <div className='placeholder'><Tag>drop values here</Tag></div>
        {values && values.map(value => this.renderValue(value))}
      </div>
    )
  }

  renderValue(value) {
    let { actions } = this.props;
    return <RawValue key={value} value={value} actions={actions} />;
  }
}

export default RawValueList;
