import mean from "lodash/mean";
import excelColumnName from "excel-column-name";
import papaparse from "papaparse";
import {
  Response,
  Survey,
  SurveyData,
  Edge,
  EdgeList,
  Matrix,
  Blueprint,
  Element,
  Connection,
  TranslationOptions,
} from "./types";

let nextID = 1;

export function getUniqueValuesFromResponse(response) {
  return getUniqueValues({ response });
}
export function getUniqueValues(state) {
  let { response } = state;
  let set = new Set<string>();

  if (response.source === "import") {
    // focus isn't included
    response.data.matrix.labels.slice(1).forEach(value => set.add(value));
  } else {
    response.data.values.forEach((entry, i) => {
      if (entry.value) set.add(entry.value);

      entry.children.forEach((nestedEntry, j) => {
        if (entry.value) set.add(nestedEntry.value);
      });
    });
  }

  // TODO: sort alphabetically?
  let values = Array.from(set.values());

  // Remove empty values
  // TODO: handle this within the responses instead
  values = values.filter(value => /\S/.test(value));

  return values;
}

// Goal is to get some variation in label widths/heights here in order
// to prevent overlap
export function splitString(string) {
  let split1 = Math.round(string.length * 0.4);
  let split2 = string.substring(split1).indexOf(" ");

  if (split2 === -1) {
    return [string];
  } else {
    let index = split1 + split2;
    let line1 = string.substring(0, index);
    let line2 = string.substring(index, string.length);
    return [line1, line2];
  }
}

export function prepareResponse(state) {
  let { response } = state;

  response.data.values.forEach((entry, index) => {
    let { value, children } = entry;
    prepareList(children, { minLength: 2, plusOne: true });
  });

  prepareList(response.data.values, { minLength: 5, plusOne: true });
}

function prepareList(values, options: any = {}) {
  while (values.length < options.minLength) {
    values.push(createPlaceholder());
  }

  // always leave room for another response
  if (options.plusOne) {
    plusOne(values);
  }
}

// Used to always make sure there's room for another value
function plusOne(values) {
  let last = values[values.length - 1];

  if (!last || last.value) {
    values.push(createPlaceholder());
  }
}

function createPlaceholder() {
  return { id: `v${nextID++}`, value: "", children: [] };
}

// TODO:
// getGraphFromSurveyResponse -- easy to build out rings
// getGraphFromMatrixResponse -- may have 3rd+ order nodes
export function getGraphFromResponse(survey: Survey, response: Response) {
  if (response.source === "import") {
    throw new Error(
      "getGraphFromResponse not implemented for imported responses"
    );
  } else {
    return getGraph({ survey, response });
  }
}

// TODO: clean entries before calling this (should also be responsible for trimming values)
export function getGraph(state) {
  let { survey } = state;
  let { focus, direction } = survey;
  let nodeIndex = {};

  let response = createCleanResponse(state.response);

  let graph = {
    direction: direction,
    getNode: identifier => nodeIndex[identifier],
    focusNode: null,
    innerNodes: [],
    outerNodes: [],
    nodes: [],
    edges: [],
  };

  function findOrCreateNode(id, label) {
    let node = graph.getNode(label);

    if (!node) {
      node = nodeIndex[label] = { id, label };
      graph.nodes.push(node);
    }

    // the same label might be referenced by multiple ids
    nodeIndex[id] = node;

    return node;
  }

  // Create a node for the focus
  let focusNode = findOrCreateNode("focus", focus);
  focusNode.focus = true;

  // Create a node for every unique label
  // TODO: make this recursive
  response.data.values.forEach(entry => {
    let { id, value } = entry;

    let node = findOrCreateNode(id, value);
    node.inner = true;

    entry.children.forEach(entry => {
      let { id, value } = entry;

      findOrCreateNode(id, value);
    });
  });

  // Create edges between nodes
  // TODO: make this recursive
  response.data.values.forEach((targetEntry, i) => {
    let from = graph.getNode(targetEntry.id);
    let to = focusNode;

    graph.edges.push({ from, to });

    targetEntry.children.forEach((causeEntry, j) => {
      let from = graph.getNode(causeEntry.id);
      let to = graph.getNode(targetEntry.id);

      graph.edges.push({ from, to });
    });
  });

  // TODO: generalize this to degrees/rings instead of focus/inner/outer
  graph.focusNode = focusNode;
  graph.innerNodes = graph.nodes.filter(node => !node.focus && node.inner);
  graph.outerNodes = graph.nodes.filter(node => !node.focus && !node.inner);

  if (graph.direction === "downstream") {
    graph.edges = graph.edges.map(edge => {
      return { ...edge, from: edge.to, to: edge.from };
    });
  }

  // otherEdge is [fromID, toID]
  response.data.otherEdges.forEach(otherEdge => {
    let from = graph.getNode(otherEdge.from);
    let to = graph.getNode(otherEdge.to);

    if (from && to) graph.edges.push({ from, to });
  });

  // remove any self edges
  graph.edges = graph.edges.filter(edge => edge.from !== edge.to);

  // remove any duplicate edges
  graph.edges = Array.from(new Set(graph.edges));

  return graph;
}

export function getOriginalMatrix(survey, response) {
  if (response.source === "import") {
    return response.data.matrix;
  } else {
    let graph = getGraphFromResponse(survey, response);
    return graphToAdjacencyMatrix(graph);
  }
}

// TODO: rename this to getMatrixFromState
export function getMatrix(state) {
  let graph = getGraph(state);
  return graphToAdjacencyMatrix(graph);
}

export function getEdgeListFromResponse(survey, response): EdgeList {
  let matrix = getOriginalMatrix(survey, response);
  return getEdgeListFromMatrix(matrix);
}

export function getEdgeListFromMatrix(matrix): EdgeList {
  let size = matrix.data.length;
  let edges: EdgeList = [];

  // if (matrix.labels.length != matrix.data.length) debugger;

  for (let row = 0; row < size; row++) {
    for (let col = 0; col < size; col++) {
      let weight = matrix.data[row][col];

      if (weight > 0) {
        let from = matrix.labels[row];
        let to = matrix.labels[col];

        if (!from || !to) {
          // TODO: this is a workaround for some busted imports where the
          // number of labels doesn't line up with the matrix data
          // debugger
          continue;
        }

        edges.push({ from, to, weight });
      }
    }
  }

  return edges;
}

export function getMatrixDomain(matrix) {
  let min = Infinity;
  let max = -Infinity;

  matrix.data.forEach(row => {
    row.forEach(value => {
      min = Math.min(value, min);
      max = Math.max(value, max);
    });
  });

  return [min, max];
}

// assumes no orphaned nodes
export function getBlueprintFromMatrix(matrix: Matrix): Blueprint {
  let edges: EdgeList = getEdgeListFromMatrix(matrix);
  let indexByLabel = {};

  let elements = matrix.labels.map((label, index) => {
    let code = codeForIndex(index);
    indexByLabel[label] = index;
    return { label, code };
  });

  let connections = edges.map(edge => {
    let { from, to } = edge;
    let i = indexByLabel[from];
    let j = indexByLabel[to];
    let weight = matrix.data[i][j];

    return { from, to, weight };
  });

  return { elements, connections };
}

// TODO: option to include untranslated values?
export function translateMatrix(
  survey: Survey,
  surveyData: SurveyData,
  matrix: Matrix,
  translationOptions
) {
  let edges = getEdgeListFromMatrix(matrix)
    .map(edge => translateEdge(survey, surveyData, edge, translationOptions))
    .filter(edge => edge.from && edge.to && edge.from !== edge.to);

  let usedLabels = new Set();
  edges.forEach(edge => {
    usedLabels.add(edge.from);
    usedLabels.add(edge.to);
  });

  // Use the master values to build the label array so the order is consistent
  let labels;

  if (translationOptions && translationOptions.categorize) {
    labels = [survey.focus, ...surveyData.categories];
  } else {
    labels = [
      survey.focus,
      ...surveyData.masterValues.map(master => master.value),
    ];
  }

  // omit labels that weren't used in this matrix (focus always included)
  labels = labels.filter(
    (label, index) => index === 0 || usedLabels.has(label)
  );

  let masterValueIndex = {};
  labels.forEach((value, index) => (masterValueIndex[value] = index));

  let data = createEmptyMatrix(labels.length);

  edges.forEach(edge => {
    let i = masterValueIndex[edge.from];
    let j = masterValueIndex[edge.to];

    data[i][j] = 1;
  });

  return { labels, data };
  //
  // // TODO: need to collapse rows/cols for duplicate aliases
  // // Easier to transform an edge list then convert to matrix?
  // let keep = {};
  //
  // let labels = matrix.labels
  //   .map((value, index) => {
  //     let master = getMasterValue(survey, surveyData, value);
  //     keep[index] = !!master;
  //     return master;
  //   })
  //   .filter((value, index) => keep[index]);                // delete untranslated labels
  //
  // let data = matrix.data
  //   .filter((row, index) => keep[index])                   // delete untranslated rows
  //   .map(row => row.filter((col, index) => keep[index]));  // delete untranslated columns
  //
  // let translatedMatrix: Matrix = { labels, data };
  // return translatedMatrix;
}

export function translateEdge(
  survey: Survey,
  surveyData: SurveyData,
  edge: Edge,
  translationOptions?: TranslationOptions
): Edge {
  let translatedEdge = {
    // from/to may be undefined but invalid edges will be filtered out later
    from: translateValue(survey, surveyData, edge.from, translationOptions),
    to: translateValue(survey, surveyData, edge.to, translationOptions),
    weight: edge.weight,
  };

  return translatedEdge;
}

export function translateValue(
  survey: Survey,
  surveyData: SurveyData,
  value: string,
  translationOptions?: TranslationOptions
): string {
  let master = getMasterValueObject(survey, surveyData, value);

  if (master) {
    if (translationOptions && translationOptions.categorize) {
      return master.category;
    } else {
      return master.value;
    }
  } else {
    return undefined;
  }
}

export function getMasterValue(
  survey: Survey,
  surveyData: SurveyData,
  value: string
) {
  let master = getMasterValueObject(survey, surveyData, value);
  return master && master.value;
}

export function getMasterCategory(
  survey: Survey,
  surveyData: SurveyData,
  value: string
) {
  let master = getMasterValueObject(survey, surveyData, value);
  return master && master.category;
}

export function getMasterValueObject(
  survey: Survey,
  surveyData: SurveyData,
  value: string
) {
  let { masterValues, aliasTable } = surveyData;

  if (value === survey.focus) {
    // TODO: still not sure what to return here
    return { focus: true, value, category: value };
  } else {
    // master may have been deleted after aliasing
    let masterID = aliasTable[value];
    return masterValues.find(
      master => (masterID && master.id === masterID) || master.value === value
    );
  }
}

// TODO: option to include values that haven't been translated
export function translatedMatrixToMasterMatrix(
  survey: Survey,
  surveyData: SurveyData,
  translatedMatrix: Matrix,
  translationOptions?: TranslationOptions
): Matrix {
  let labels = getMasterMatrixLabels(survey, surveyData, translationOptions);
  let data = createEmptyMatrix(labels.length);

  let masterIndex = {};
  labels.forEach((label, index) => (masterIndex[label] = index));

  getEdgeListFromMatrix(translatedMatrix).forEach(edge => {
    let i = masterIndex[edge.from];
    let j = masterIndex[edge.to];

    data[i][j] = 1;
  });

  return { labels, data };
}

export function getMasterMatrixLabels(
  survey: Survey,
  surveyData: SurveyData,
  translationOptions?: TranslationOptions
) {
  let labels;

  if (translationOptions && translationOptions.categorize) {
    let { categories } = surveyData;
    labels = [survey.focus, ...categories];
  } else {
    let values = surveyData.masterValues.map(master => master.value);
    labels = [survey.focus, ...values];
  }

  return labels;
}

export function getResponseTranslationStats(
  survey: Survey,
  surveyData: SurveyData,
  response: Response
) {
  let values = getUniqueValuesFromResponse(response);
  let remaining = 0;

  let mappings = values.map(value => {
    // TODO: need to be able to differentiate untranslated from ignored
    let masterValue = getMasterValue(survey, surveyData, value);

    if (!masterValue) {
      remaining += 1;
      masterValue = "";
    }

    return [value, masterValue];
  });

  let matrix = getTranslatedMatrixFromResponse(survey, surveyData, response);
  let edges = getEdgeListFromMatrix(matrix);

  return { remaining, mappings, edges };
}

export function getMasterMatrixFromResponse(
  survey: Survey,
  surveyData: SurveyData,
  response: Response,
  translationOptions?: TranslationOptions
): Matrix {
  let originalMatrix = getOriginalMatrix(survey, response);
  let translatedMatrix = translateMatrix(
    survey,
    surveyData,
    originalMatrix,
    translationOptions
  );

  return translatedMatrixToMasterMatrix(
    survey,
    surveyData,
    translatedMatrix,
    translationOptions
  );
}

export function getTranslatedMatrixFromResponse(
  survey: Survey,
  surveyData: SurveyData,
  response: Response,
  translationOptions?: TranslationOptions
): Matrix {
  let originalMatrix = getOriginalMatrix(survey, response);
  let translatedMatrix = translateMatrix(
    survey,
    surveyData,
    originalMatrix,
    translationOptions
  );
  return translatedMatrix;
}

export function codeForIndex(i) {
  return excelColumnName.intToExcelCol(i + 1).toLowerCase();
}

export function matrixToCSV(matrix) {
  let { labels, data } = matrix;
  let rows = [];

  // codes first then labels
  rows.push(["", "", ...labels.map((v, i) => codeForIndex(i))]);
  rows.push(["", "", ...labels]);

  for (var i = 0; i < data.length; i++) {
    rows.push([codeForIndex(i), labels[i], ...data[i]]);
  }

  return papaparse.unparse(rows);
}

export function graphToAdjacencyMatrix(graph) {
  let labels = graph.nodes.map(node => node.label);
  let data = createEmptyMatrix(labels.length);

  let indexByLabel = {};
  labels.forEach((label, index) => (indexByLabel[label] = index));

  graph.edges.forEach(edge => {
    let i = indexByLabel[edge.from.label];
    let j = indexByLabel[edge.to.label];

    data[i][j] = 1;
  });

  return { labels, data };
}

export function createEmptyMatrix(size) {
  let data = new Array(size);

  for (let i = 0; i < size; i++) {
    data[i] = new Array(size);

    for (let j = 0; j < size; j++) {
      data[i][j] = 0;
    }
  }

  return data;
}

export function getAggregateMatrix(survey, surveyData, filter?: RegExp) {
  let masterMatrix;
  let size;

  let responses = filterResponses(surveyData.responses, filter);

  responses.forEach(response => {
    let matrix = getMasterMatrixFromResponse(survey, surveyData, response);

    if (masterMatrix) {
      for (let i = 0; i < size; i++) {
        for (let j = 0; j < size; j++) {
          masterMatrix.data[i][j] += matrix.data[i][j];
        }
      }
    } else {
      masterMatrix = matrix;
      size = matrix.labels.length;
    }
  });

  // handle the case when all responses have been filtered
  // TODO: grab labels from master values instead of first response
  if (!masterMatrix && surveyData.responses.length > 0) {
    let matrix = getMasterMatrixFromResponse(
      survey,
      surveyData,
      surveyData.responses[0]
    );
    masterMatrix = {
      labels: matrix.labels,
      data: createEmptyMatrix(matrix.labels.length),
    };
  }

  return masterMatrix;
}

export function getAggregateMatrixFromResponses(
  survey: Survey,
  surveyData: SurveyData,
  responses: Response[],
  translationOptions?: TranslationOptions
) {
  let masterMatrix;
  let size;

  responses.forEach(response => {
    let matrix = getMasterMatrixFromResponse(
      survey,
      surveyData,
      response,
      translationOptions
    );

    if (masterMatrix) {
      for (let i = 0; i < size; i++) {
        for (let j = 0; j < size; j++) {
          masterMatrix.data[i][j] += matrix.data[i][j];
        }
      }
    } else {
      masterMatrix = matrix;
      size = matrix.labels.length;
    }
  });

  return masterMatrix;
}

export function getMatchingResponses(surveyData, filter) {
  return filterResponses(surveyData.responses, filter);
}

export function filterResponses(responses, filter) {
  return responses.filter(response => !filter || filter.test(response.tags));
}

export function createCleanResponse(rawResponse) {
  let response = JSON.parse(JSON.stringify(rawResponse)); // deep clone

  response.data.values = response.data.values.filter(entry => entry.value);
  response.data.values.forEach(entry => {
    entry.children = entry.children.filter(entry => entry.value);
  });

  // TODO: remove orphaned edges?
  // response.data.otherEdges.filter(...)

  return response;
}

export function getSurveyStats(survey, surveyData) {
  function getNodeCount(matrix) {
    return matrix.labels.length;
  }

  function getEdgeCount(matrix) {
    let n = matrix.data.length;
    let total = 0;

    for (var i = 0; i < n; i++) {
      for (var j = 0; j < n; j++) {
        if (matrix.data[i][j] === 1) total++;
      }
    }

    return total;
  }

  // directed: (2 * E) / (V * (V - 1))
  // undirected: E / (V * (V - 1))
  function getDensity(matrix) {
    let V = getNodeCount(matrix);
    let E = getEdgeCount(matrix);
    return (2 * E) / (V * (V - 1));
  }

  // ------------------

  let values = {
    nodeReduction: [],
    edgeReduction: [],
    densityIncrease: [],
  };

  surveyData.responses.forEach(response => {
    if (response.ignored) return;

    // Ended up being easier to work with the matrices instead of trying to
    // build a translated graph
    let original = getOriginalMatrix(survey, response);
    let translationOptions: TranslationOptions = { categorize: false };
    let translated = translateMatrix(
      survey,
      surveyData,
      original,
      translationOptions
    );

    // change in node / edge counts
    values.nodeReduction.push(
      getNodeCount(original) - getNodeCount(translated)
    );
    values.edgeReduction.push(
      getEdgeCount(original) - getEdgeCount(translated)
    );

    // change in density
    values.densityIncrease.push(
      (getDensity(translated) - getDensity(original)) / getDensity(original)
    );

    // TODO: change in average path length
  });

  return {
    averageNodeReduction: mean(values.nodeReduction),
    averageEdgeReduction: mean(values.edgeReduction),
    averageDensityIncrease: mean(values.densityIncrease),
    averagePathLengthReduction: NaN,
  };
}

export function importCSV(string) {
  // TODO: use papaparse and pass data to importMatrix
}

// export function importMatrix(data) {
//   let response = {values: [], otherEdges: []};
//   let header = data[0];
//   let focus = header[0];
//
//   // collect all the edges
//   // build explore array starting with focus
//   //
//   // start with focus
//   // find every node connected to focus and add to values list (1s in first column)
//   // for each node, find every node connected to node and add to children (repeat)
//   //
//   // for every edge:
//   //   if TO degree is less than FROM degree, add as a child
//   //   otherwise add as other edge {from: id, to: id}
//   let nodes = [];
//
//   for (let row = 1; row < data.length; row++) {
//     let node = {value: data[row][0], edges: []};
//
//     for (let col = 1; col < data[row].length; col++) {
//       if (data[row][col] === 1) {
//         node.edges.push(header[col]);
//       }
//     }
//
//     nodes.push(node);
//   }
//
//   // build values/otherEdges from tree and map
//
//   let focusNode = nodes[0];
//
//   focusNode.edges.forEach(toNode => {
//
//   })
//
//   // start with focus
//   let explore = [{value: focus, depth: 0}];
//   let explored = new Set();
//
//   let children = {};
//   let depths = {};
//
//   while (explore.length) {
//     let { depth, value } = explore.shift();
//
//     edges.forEach(edge => {
//       if (edge.to === value) {
//         if (children[value]) {
//           children[value].push(edge.from);
//           depths[value] = Math.min(depths[value], depth);
//         } else {
//           children[value] = [edge.from];
//           depths[value] = depth;
//         }
//
//         if (!explored.has(edge.from)) {
//           explore.push({value: edge.from, depth: depth + 1});
//         }
//       }
//     })
//   }
//
//   return response;
// }

const UUID_CHARS = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
  ""
);

export function uuid(length = 8, radix = 62) {
  let id = "";

  for (let i = 0; i < length; i++) {
    id += UUID_CHARS[0 | (Math.random() * radix)];
  }

  return id;
}
